import {Component, OnInit, Output, EventEmitter, Input, inject} from '@angular/core';
import {objectives, audience, typePublic} from 'app/shared/general/datageneral';
import { AudienceService } from 'src/app/core/services/api/audience.service';
import { ObjectivesService } from 'src/app/core/services/api/objectives.service';
import { TypePublicService } from 'src/app/core/services/api/type-public.service';

@Component({
    selector: 'app-rate-campaign',
    templateUrl: './rate-campaign.component.html',
    styleUrls: ['./rate-campaign.component.scss']
})
export class RateCampaignComponent implements OnInit {

    @Output() onRate = new EventEmitter<any>();
    @Input() data: any;
    @Input() weight: boolean;

    rateCampaign = [
        {
            name: 'Noticia',
            rates: [
                {title: 'Interes Público', value: 0},
                {title: 'Nivel de Novedad', value: 0},
                {title: 'Nivel de Actualidad', value: 0}
            ]
        },
        {
            name: 'Cliente',
            rates: [
                {title: 'Nivel de Autoridad', value: 0},
                {title: 'Nivel de Popularidad', value: 0}
            ]
        },
        {
            name: 'Voceros',
            rates: [
                {title: 'Nivel de Autoridad', value: 0},
                {title: 'Nivel de Popularidad', value: 0}
            ]
        }
    ]

    campaignValor: { title: string; value: number; options: any }[] = [
        {
            title: 'Objetivo',
            value: null,
            options: null
        },
        {
            title: 'Público Objetivo',
            value: null,
            options: null
        },
        {
            title: 'Audiencia',
            value: null,
            options: null
        }
    ]

    objectivesService = inject(ObjectivesService);
    typePublicService = inject(TypePublicService);
    audienceService = inject(AudienceService);

    ngOnInit(): void {
        this.objectivesService.getAll()
        .subscribe(response => {
            const { data: objectives } = response;
            this.campaignValor[0].options = objectives;
        });
        this.typePublicService.getAll()
        .subscribe(response => {
            const { data: typePublic } = response;
            this.campaignValor[1].options = typePublic;
        });
        this.audienceService.getAll()
        .subscribe(response => {
            const { data: audience } = response;
            this.campaignValor[2].options = audience;
        });
    }

    ngOnChanges() {
        if (this.data) {
            this.campaignValor[0].value = this.data.tipoObjetivo;
            this.campaignValor[1].value = this.data.tipoPublico;
            this.campaignValor[2].value = this.data.tipoAudiencia;
            this.rateCampaign[0]['rates'][0].value = this.data.interesPublico ? this.data.interesPublico : 0;
            this.rateCampaign[0]['rates'][1].value = this.data.novedad ? this.data.novedad : 0;
            this.rateCampaign[0]['rates'][2].value = this.data.actualidad ? this.data.actualidad : 0;
            this.rateCampaign[1]['rates'][0].value = this.data.autoridadCliente ? this.data.autoridadCliente : 0;
            this.rateCampaign[1]['rates'][1].value = this.data.mediaticoCliente ? this.data.mediaticoCliente : 0;
            this.rateCampaign[2]['rates'][0].value = this.data.autoridadVoceros ? this.data.autoridadVoceros : 0;
            this.rateCampaign[2]['rates'][1].value = this.data.mediaticoVoceros ? this.data.mediaticoVoceros : 0;
        }
    }

    onChange() {
        this.emitInfo();
    }

    emitInfo() {
        const data = {
            tipoPublico: this.campaignValor[1].value,
            tipoObjetivo: this.campaignValor[0].value,
            tipoAudiencia: this.campaignValor[2].value,
            interesPublico: this.rateCampaign[0]['rates'][0].value,
            novedad: this.rateCampaign[0]['rates'][1].value,
            actualidad: this.rateCampaign[0]['rates'][2].value,
            autoridadCliente: this.rateCampaign[1]['rates'][0].value,
            mediaticoCliente: this.rateCampaign[1]['rates'][1].value,
            autoridadVoceros: this.rateCampaign[2]['rates'][0].value,
            mediaticoVoceros: this.rateCampaign[2]['rates'][1].value
        }
        this.onRate.emit(data);
    }

    view(event, max, ind, dex) {
        const number = event.target.valueAsNumber;
        if (number > max) {
            this.rateCampaign[ind].rates[dex]['value'] = max;
        }
    }

    onlyNumber(e, input) {
        const key = e.keyCode || e.which;
        const character = String.fromCharCode(key);
        let inputValue;
        if (input) {
            inputValue = input + character
        } else {
            inputValue = character
        }
        const regex = /\(?(^[0-5]*$)/;
        return regex.test((inputValue)) && inputValue.length == 1;
    }

}
