import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpeedNetworkComponent} from './speed-network.component';
import {MaterialModule} from './../../material/material.module';
import {NgxEchartsModule} from 'ngx-echarts';

@NgModule({
    declarations: [
        SpeedNetworkComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
    ]
})
export class SpeedNetworkModule {
}
