import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Api } from '../../contracts/classes/api.class';
import { Observable } from 'rxjs';
import { DetailedMediaPlanLegacy, ApiResponseDetailedMediaPlanLegacy } from '../../contracts/models/detailed-media-plan.model';
import { DpmDTO, DpmUpdateDTO } from 'src/app/core/contracts/models/detailed-media-plan.model';

@Injectable({providedIn: 'root'})
export class MediaPlanService extends Api {

  getAll() {
    return this.http.get(`${this.url}/planMedios`);
  }

  getList() {
    return this.http.get(`${this.url}/planMedios/data/getList`);
  }

  getListPagination() {
    return this.http.get(`${this.url}/planMedios/data/list`);
  }

  getListPaginationByDetail(page) {
    return this.http.get(`${this.url}/planMedios/data/listDetails${page}`);
  }

  getSearchPagination(page) {
    return this.http.get(`${this.url}/planMedios/data/listDetails?search-plan-medio=${page}`);
  }

  getListPaginationByStatus(estado, page) {
    return this.http.get(`${this.url}/planMedios/data/listEstado/${estado}${page}`);
  }

  getListKanbanByStatus(estado, page) {
    return this.http.get(`${this.url}/plan-de-medios/kanban/${estado}${page}`);
  }

  getOnlyDetail(id: any) {
    return this.http.get(`${this.url}/planMedios/detalle/${id}`);
  }

  getId(id: number) {
    return this.http.get(`${this.url}/planMedios/${id}`).pipe(
      map((response: any) => {
        return response.planMedio;
      })
    );
  }

  getByLogged() {
    return this.http.get(`${this.url}/planMedios/data/getListByLogged`);
  }

  planMediosPorStatusBitacora(data: any) {
    return this.http.post(`${this.url}/planMedios/data/planMediosPorStatusBitacora`, data)
    .pipe(map( (response :any) => response.data))
  }
  
  planMediosPorStatusBitacoraByLogged(data: any) {
    return this.http.post(`${this.url}/planMedios/data/planMediosPorStatusBitacoraByLogged`, data)
    .pipe(map( (response :any) => response.data))
  }

  getByCampaign(id) {
    return this.http.get(`${this.url}/planMedios/data/getListByCampaign/${id}`);
  }

  getByClient(id) {
    return this.http.get(`${this.url}/planMedios/data/getListByCliente/${id}`);
  }

  create(data: any) {
    return this.http.post(`${this.url}/planMedios`, data);
  }

  edit(data: any) {
    return this.http.put(`${this.url}/planMedios/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/planMedios/${id}`);
  }

  createDetail(data: DpmDTO) {
    return this.http.post(`${this.url}/detallePlanMedios`, data);
  }

  editDetail(data: DpmUpdateDTO, id: number) {
    return this.http.put(`${this.url}/detallePlanMedios/${id}`, data);
  }

  /**
   * @description legacy
   * @param id 
   * @returns 
   */

   getDetailLegacy(id: string) {
    return this.http.get(`${this.url}/detallePlanMedios/${id}`);
  }

  getDetail(id: string): Observable<DetailedMediaPlanLegacy> {
    return this.http.get<ApiResponseDetailedMediaPlanLegacy>(`${this.url}/detallePlanMedios/${id}`)
    .pipe(map(response => response.detallePlanMedio));
  }

  getDetailByContact(id: any) {
    return this.http.get(`${this.url}/detallePlanMedios/data/getListByContacto/${id}`);
  }

  getDetailByContactLogged(id: any) {
    return this.http.get(`${this.url}/detallePlanMedios/data/getListByContactoAndLogged/${id}`);
  }

  deleteDetail(id: any) {
    return this.http.delete(`${this.url}/detallePlanMedios/${id}`);
  }

  createLink(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/associateDPM`, data);
  }

  getListLink(id) {
    return this.http.get(`${this.url}/detallePlanMedios/data/getListAssociatedDPM/${id}`);
  }

  deleteLink(id) {
    return this.http.get(`${this.url}/detallePlanMedios/data/dissociateDPM/${id}`);
  }

  saveResult(data) {
    return this.http.post(`${this.url}/resultadoPlataformas/data/saveResultados`, data);
  }

  getResults(id) {
    return this.http.get(`${this.url}/resultadoPlataformas/data/getListByDPM/${id}`);
  }

  getImageResults(id){
    return this.http.get(`${this.url}/resultadoPlataformas/data/images/${id}`);
  }


  assignSpokesmen(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/updateMassVoceros`, data);
  }

  sendEmail(data) {
    return this.http.post(`${this.url}/notaPrensas/data/sendMassNotaPrensa`, data);
  }

  sendEmailReport(data) {
    return this.http.post(`${this.url}/resultadoPlataformas/data/sendReporte`, data);
  }

  sendEmailImpact(data) {
    return this.http.post(`${this.url}/resultadoPlataformas/data/sendImpactosByPlataformas`, data);
  }

  changeSample(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/updateMassMuestras`, data);
  }

  getGraphicPlatforms() {
    return this.http.get(`${this.url}/detallePlanResultadoPlataformas/data/getCountByPlataformas`);
  }

  getGraphicValor() {
    return this.http.get(`${this.url}/detallePlanResultadoPlataformas/data/getValorizadoByYears`);
  }

  getGraphicStatus() {
    return this.http.get(`${this.url}/detallePlanMedios/data/getCountByEstado`);
  }

  getGraphicData() {
    return this.http.get(`${this.url}/baseDeDatos/data/getCount`);
  }

  generatePlan(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/generatePlanMedio`, data);
  }

  sendExpertSystem(id) {
    return this.http.get(`${this.url}/detallePlanMedios/data/addSistemaExperto/${id}`);
  }

  getSendList() {
    return this.http.get(`${this.url}/detallePlanMedios/data/getListByEstado/1`);
  }

  getonlySendsList() {
    return this.http.get(`${this.url}/detallePlanMedios/data/getListSistemaExpertoEnviados`);
  }

  getnoSendsList() {
    return this.http.get(`${this.url}/detallePlanMedios/data/getListSistemaExpertoNoEnviados`);
  }

  sendExpertSystemMass(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/addMassSistemaExperto`, data);
  }

  deleteExpertSystem(id) {
    return this.http.get(`${this.url}/detallePlanMedios/data/deleteSistemaExperto/${id}`);
  }

  deleteExpertSystemMass(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/deleteMassSistemaExperto`, data);
  }

  transferContact(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/transferir`, data);
  }

  transferContactMass(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/transferirMass`, data);
  }

  changeStatus(data) {
    return this.http.post(`${this.url}/registros`, data);
  }

  updateMassTier(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/updateMassTipoTier`, data);
  }

  updateMassStatus(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/updateMassStatus`, data);
  }

  getAllDetailsByLogged(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/getListByLogged`, data);
  }

  getAllDetailsByPagination(data, page) {
    return this.http.post(`${this.url}/detallePlanMedios/data/getListByLoggedV2${page}`, data);
  }
}
