import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface NotificationWithButtonsSnackbarDto {
    icon: string;
    message: string;
    description: string;
    withActionButton: boolean;
}

@Component({
    selector: 'app-notification-with-buttons',
    standalone: true,
    imports: [NgIf, MatIconModule],
    templateUrl: './notification-with-buttons.component.html',
})
export class NotificationWithButtonsComponent {
    
    public snackBarRef: MatSnackBarRef<NotificationWithButtonsComponent> = inject(MatSnackBarRef);
    public data:NotificationWithButtonsSnackbarDto = inject(MAT_SNACK_BAR_DATA);
    
    public showDetails() {
        this.snackBarRef.dismissWithAction();
    }
    
    public close() {
        this.snackBarRef.dismiss();
    }
}
