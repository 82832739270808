import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpokesmenService } from 'src/app/core/services/api/spokesmen.service';
import { CustomerService } from 'src/app/core/services/api/customer.service';

import { Swal2 } from 'src/app/core/utils/swal2.util';
import { Observable } from 'rxjs';
import { ClienteVoceros } from 'src/app/core/contracts/models/cliente-voceros';


@Component({
    selector: 'app-asignar-clientes-form',
    templateUrl: './asignar-clientes-form.component.html',
    styleUrls: ['./asignar-clientes-form.component.scss']
})
export class AsignarClientesFormComponent implements OnInit {
    public loading: boolean = false;

    myForm = this.formBuilder.group({
        cliente_id: new FormControl<number | null>(null, [Validators.required]),
        cargo: new FormControl<string | null>(null, [Validators.required]),
        filter: new FormControl<string | null>(null)
    });

    cliente: ClienteVoceros = {
        cliente_id: null,
        idVocero: null,
        cargo: null,
    }

    clientes: any[] = [];

    title = 'Agregar cliente';
    loader = true;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarClientesFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: ClienteVoceros, id: number },
        private customerService: CustomerService,
        private spokesmenService: SpokesmenService,
        private swal: Swal2
    ) {
        if (data.row) {
            this.title = 'Editar cliente';
        }
    }

    ngOnInit(): void {

        this.customerService.getListSelect().subscribe((response: any) => {
            const { clientes } = response;
            this.clientes = clientes;
            this.loader = false;
        });

        if (this.data.row) {
            this.myForm.controls.cliente_id.setValue(this.data.row.cliente_id);
            this.myForm.controls.cargo.setValue(this.data.row.cargo);
            this.myForm.controls.cliente_id.disable();
        }

    }

    onSubmit() {
        this.cliente = {
            idVocero: this.data.id,
            cliente_id: this.myForm.controls.cliente_id.value,
            cargo: this.myForm.controls.cargo.value,
        }

        let observable: Observable<any> | undefined = undefined

        if (this.data.row) {
            this.cliente.id = this.data.row.id;
            observable = this.spokesmenService.editClienteVoceros(this.cliente)
        } else {
            observable = this.spokesmenService.createClienteVoceros(this.cliente)
        }
        this.loading = true;
        observable.subscribe((response: any) => {
            this.dialogRef.close(response);
            this.loading = false;
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.status === 400) {
                this.error(error);
            }
            this.loading = false;
        });
    }

    error(error: any) {
        if (error.error.errors.vocero_id) {
            this.swal.showToast(error.error.errors.vocero_id[0], 'error');
        }
    }
}
