import packageInfo from '../../package.json';

export const environment = {
    production: false,
    version: `${packageInfo.version}-test`,

    mail_support: 'mariela.mamani@makingconnexion.com',
    
    app_urls: {
        source: 'https://adp-api.pruebasgt.com',
        clipping: 'https://adp-clipping.pruebasgt.com'
    },

    // Antiguos controles
    pucp: false,
    // Nuevos controles hasta que se realice el SaaS
    tenant: 'Local',
    clipping: true,
    sistemaExperto: true,
    kanban: true,

    images: {
        DEFAULT_AVATAR_PATH: './assets/images/default-avatar.png',
        DEFAULT_NO_IMAGE: './assets/images/noimage.png',
        LOGOTYPE: './assets/images/logotype.png'
    },
    
    localStorageKeys: {
        AUTH: 'AUTH_STG',
        TOKEN: 'TOKEN_STG'
    }
};
