import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Api } from '../../contracts/classes/api.class';
import { ClienteVoceros } from '../../contracts/models/cliente-voceros';
import { Spokesmen } from '../../contracts/models/spokesmen';

@Injectable({ providedIn: 'root' })
export class SpokesmenService extends Api{

  getAll() {
    return this.http.get(`${this.url}/voceros`);
  }

  getId(id: number) {
    return this.http.get(`${this.url}/voceros/${id}`).pipe(
      map((response: any) => {
        return response.vocero;
      })
    );
  }

  getListByCliente(id: number) {
    return this.http.get(`${this.url}/clienteVoceros/data/getListByCliente/${id}`);
  }

  create(data: Spokesmen) {
    return this.http.post(`${this.url}/voceros`, { ...data.persona, famoso: data.famoso });
  }

  edit(data: Spokesmen) {
    return this.http.put(`${this.url}/voceros/${data.id}`, { id: data.id, ...data.persona, famoso: data.famoso });
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/voceros/${id}`);
  }

  getAllClienteVoceros(id: any) {
    return this.http.get(`${this.url}/clienteVoceros/data/getListByVocero/${id}`);
  }


  createClienteVoceros(data: ClienteVoceros) {
    return this.http.post(`${this.url}/clienteVoceros`, data);
  }

  editClienteVoceros(data: ClienteVoceros) {
    return this.http.put(`${this.url}/clienteVoceros/${data.id}`, data);
  }

  deleteClienteVoceros(id: number) {
    return this.http.delete(`${this.url}/clienteVoceros/${id}`);
  }
}
