import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Api } from '../../contracts/classes/api.class';
import { ClienteEncargados } from '../../contracts/models/cliente-encargados';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends Api {

  getAll() {
    return this.http.get(`${this.url}/clientes`);
  }

  getAllActive(id) {
    return this.http.get(`${this.url}/clientes?active=${id}`);
  }

  getList() {
    return this.http.get(`${this.url}/clientes/data/getList`);
  }

  getListSelect() {
    return this.http.get(`${this.url}/clientes/select`);
  }

  getId(id: number) {
    return this.http.get(`${this.url}/clientes/${id}`).pipe(
      map((response: any) => {
        return response.cliente;
      })
    );
  }

  create(data: any) {
    return this.http.post(`${this.url}/clientes`, data);
  }

  update(data: any) {
    return this.http.post(`${this.url}/clientes/${data.get('id')}?_method=PUT`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/clientes/${id}`);
  }

  getAllClienteEncargados(id: any) {
    return this.http.get(`${this.url}/clienteEncargados/data/getListByCliente/${id}`);
  }

  createClienteEncargados(data: ClienteEncargados) {
    return this.http.post(`${this.url}/clienteEncargados`, data);
  }

  updateClienteEncargados(data: ClienteEncargados) {
    return this.http.put(`${this.url}/clienteEncargados/${data.id}`, data);
  }

  deleteClienteEncargados(id: any) {
    return this.http.delete(`${this.url}/clienteEncargados/${id}`);
  }

  getPlatformImpact(data: any) {
    return this.http.post(`${this.url}/resultadoPlataformas/data/getImpactosByPlataformas`, data);
  }

  getMonthlyImpact(data: any) {
    return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosMensualCampanaAndPlatform`, data);
  }

  addMember(data: any) {
    return this.http.post(`${this.url}/clientes/data/addMember`, data);
  }

  editMember(data: any) {
    return this.http.post(`${this.url}/clientes/data/editMember`, data);
  }

  deleteMember(id: number) {
    return this.http.get(`${this.url}/clientes/data/deleteMember/${id}`);
  }

  sendCredencials(id: number) {
    return this.http.get(`${this.url}/clientes/data/enviarCredenciales/${id}`);
  }

  clienteEnvios(data: any) {
    return this.http.post(`${this.url}/clienteEnvios`, data);
  }

  updateclienteEnvio(data: any, id: number) {
    return this.http.put(`${this.url}/clienteEnvios/${id}`, data);
  }

  deleteclienteEnvio(id: any) {
    return this.http.delete(`${this.url}/clienteEnvios/${id}`);
  }

  calculateCron(data: any) {
    return this.http.post(`${this.url}/clienteEnvios/data/nextSendAndDateRange`, data);
  }

}
