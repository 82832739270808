import {Component, OnInit, Inject} from '@angular/core';
import {Option} from 'app/core/contracts/models/option';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicSelectService} from 'src/app/core/services/api/dynamic-select.service';
import {UntypedFormBuilder, NgForm} from '@angular/forms';

import {PlacesItems} from 'src/app/shared/general/places';
import {Observable} from 'rxjs';
import {startWith, tap} from 'rxjs/operators';
import { Address } from 'src/app/core/contracts/models/address.model';

@Component({
    selector: 'app-types-of-addresses-form',
    templateUrl: './types-of-addresses-form.component.html',
    styleUrls: ['./types-of-addresses-form.component.scss']
})
export class TypesOfAddressesFormComponent implements OnInit {

    addresses: Option[] = [];
    address: Address = {
        idTipoDireccion: null,
        tipo_direccion: null,
        direccion: null
    };

    title = 'Agregar dirección';
    buttonSubmit = true;

    departmentos = [];
    provincias = [];
    distritos = [];

    districtForm = this.formBuilder.group({
        departamento: ['15'],
        provincia: ['1501'],
        distrito: [null],
        filter1: [null],
        filter2: [null],
        filter3: [null]
    });

    changeDepartment$: Observable<any>;
    changeProv$: Observable<any>;

    distSelect: any;
    countD: number = 1;
    countP: number = 1;

    constructor(
        public dialogRef: MatDialogRef<TypesOfAddressesFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { data: Address, district: boolean },
        private dynamicSelectService: DynamicSelectService,
        private places: PlacesItems,
        private formBuilder: UntypedFormBuilder
    ) {
        if (data.data) {
            this.title = 'Editar dirección';
            this.address.tipo_direccion = data.data.tipo_direccion;
            this.address.idTipoDireccion = data.data.tipo_direccion.id;
            this.address.direccion = data.data.direccion;
        }
    }

    ngOnInit(): void {

        this.dynamicSelectService.getListBySlug('address').subscribe((response: any) => {
            this.addresses = response.tipoAtributos;
        });

        if (this.data.district) {
            this.departmentos = this.places.getDepartamentos();
            if (this.data.data && this.data.data.ubigeo) {
                this.distSelect = this.places.findPlace(this.data.data.ubigeo);
                this.districtForm.get('departamento').setValue(this.distSelect.department_id);
            }
        }

        this.changeDepartment$ = this.districtForm.get('departamento').valueChanges.pipe(
            startWith(this.distSelect ? this.distSelect.department_id : '15'),
            tap(dep => {
                if (dep) {
                    if (this.countD != 1) {
                        this.districtForm.get('provincia').setValue(null);
                        this.districtForm.get('distrito').setValue(null);
                    }

                    this.provincias = this.places.getProvincias(dep);
                    this.distritos = [];
                    if (this.distSelect && this.countD == 1) {
                        this.districtForm.get('provincia').setValue(this.distSelect.province_id);
                    }
                }
                this.countD++;
            })
        )

        this.changeProv$ = this.districtForm.get('provincia').valueChanges.pipe(
            startWith(this.distSelect ? this.distSelect.province_id : '1501'),
            tap(dep => {
                if (dep) {
                    if (this.countP != 1) {
                        this.districtForm.get('distrito').setValue(null);
                    }
                    this.distritos = this.places.getDistritos(dep);
                    if (this.distSelect && this.countP == 1) {
                        this.districtForm.get('distrito').setValue(this.distSelect.id);
                    }
                }
                this.countP++;
            })
        )


    }

    onSubmit(form: NgForm) {
        this.buttonSubmit = false;
        if (form.invalid) {
            this.buttonSubmit = true;
            return;
        }
        this.address.tipo_direccion = this.addresses.find(a => a.id === this.address.idTipoDireccion);
        if (this.data.district) {
            this.address.ubigeo = this.districtForm.get('distrito').value;
        }
        this.dialogRef.close({...this.address});
    }

    cancel() {
        this.dialogRef.close(this.data.data);
    }

    clear(form) {
        this.districtForm.get(form).setValue(null);
    }

}
