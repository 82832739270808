import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { MediaPlatformSelect, MediaProgramSelect, PersonaSelect } from 'src/app/core/contracts/models/form-input.model';

import { environment } from 'environments/environment';
import { AbstractControl, FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { ContactBitacoraForm } from '../../pages/media-plan/media-plan-form/interfaces/contact-bitacora-form.interface';
import { ApiResponse } from '../contracts/models/api-response.model';

export type ModelFormGroup<T> = FormGroup<{ [K in keyof T]: FormControl<T[K]>; }>;

/**@version Apiv2.0 */
@Injectable({ providedIn: 'root' })
export class JournalistFormService {

    private url: string = `${environment.app_urls.source}/api/v2`;
    private form: ModelFormGroup<ContactBitacoraForm>;
    private readonly NEW_FORM : ContactBitacoraForm = {
        contact             : 0,
        contact_program     : 0,
        media_platforms     : [],
        spokesmens          : [],
        note_type           : 1,
        phase_type          : 1,
        tier_type           : 1,
        registered_samples  : 0,
        sent_samples        : 0,
        verified_samples    : 0,
        observation         : 'nuevo',
    }
    constructor( 
        private http: HttpClient,
        private formBuilder: NonNullableFormBuilder
    ) { }

    public get new_form(): ContactBitacoraForm {
        return this.NEW_FORM;
    }

    public createForm( data: ContactBitacoraForm ): ModelFormGroup<ContactBitacoraForm> {
        this.form = this.formBuilder.group({
            contact:            new FormControl( data.contact , [Validators.required]),
            contact_program:    new FormControl({ value: data.contact_program , disabled: true }, [Validators.required]),
            media_platforms:    new FormControl({value: data.media_platforms, disabled: true}, [Validators.required]),
            spokesmens:         new FormControl(data.spokesmens, [Validators.required]),
            note_type:          new FormControl(data.note_type, [Validators.required]),
            phase_type:         new FormControl(data.phase_type, [Validators.required]),
            tier_type:          new FormControl(data.tier_type, [Validators.required]),
            registered_samples: new FormControl(data.registered_samples, [Validators.required, Validators.min(0)]),
            sent_samples:       new FormControl(data.sent_samples, [Validators.required, Validators.min(0), this.exceededValidator('registered_samples')]),
            verified_samples:   new FormControl(data.verified_samples, [Validators.required, Validators.min(0), this.exceededValidator('sent_samples')]),
            observation:        new FormControl(data.observation, [Validators.required]),
        });
        return this.form;
    }

    private exceededValidator(form) {
        return (control: AbstractControl): { 'maximumExceeded': boolean } => {
            const value = control.value
            if (control.parent) {
                if (value > control.parent.get(form).value) {
                    return {'maximumExceeded': true}
                } else {
                    return null
                }
            } else {
                return null
            }
  
        }
    }

    /**@version Apiv2.0 */
    public getSpeakersSelectByCampaignId( campaign_id: number ): Observable<PersonaSelect[]> {
        const api_url = `${this.url}/campaign-speaker/select/get-speakers-by-campaign/${campaign_id}`
        return this.http.get<ApiResponse>(api_url)
        .pipe( map( response => response.data ) );
    }

    /**@version Apiv2.0 */
    public getProgramSelectByContactId( contact_id: number ): Observable<MediaProgramSelect[]> {
        const api_url = `${this.url}/programa-contacto/select/get-program-by-contact/${contact_id}`
        return this.http.get<ApiResponse>( api_url )
        .pipe( map( response => response.data) );
    }

    /**@version Apiv2.0 */
    public getPlatformSelectByContactAndProgram( contact_id: number, program_id: number ): Observable<MediaPlatformSelect[]> {
        const api_url = `${this.url}/programa-contacto/select/get-platform-by/contact/${contact_id}/program/${program_id}`;
        return this.http.get<ApiResponse>( api_url )
        .pipe( map( response => response.data ) );
    }
}
