<div mat-dialog-title>
    <h3 style="margin-bottom: 5px;">{{data.title}}</h3>
    <mat-divider></mat-divider>
</div>

<mat-dialog-content>
    <div *ngIf="usersWithoutMail.length" class="alarm">
        Los siguientes contactos no tiene email:
        <span *ngFor="let user of usersWithoutMail">
            {{user.nombres}} {{user.apellidos}}
        </span>
    </div>
    <form [formGroup]="emailForm">
        <mat-form-field style="width: 100%;" floatLabel="always">
            <mat-label>Para</mat-label>
            <mat-chip-grid #chipList>
                <mat-basic-chip class="emailName" *ngFor="let email of emails"
                    [removable]="removable" (removed)="remove(email.email)" [matTooltip]="email.email"
                    [matTooltipDisabled]="!email.isContact">
                    <span>{{email.displayName}}</span>
                    <mat-icon matChipRemove *ngIf="removable">close</mat-icon>
                </mat-basic-chip>
                <input #fruitInput formControlName="to" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                    (matChipInputTokenEnd)="add($event)">
            </mat-chip-grid>
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let contact of contacts| search: emailForm.get('to').value:['displayName','email']"
                    [disabled]="isSelect(contact)" [value]="contact">
                    <strong>{{contact.displayName}}</strong> ({{contact.email}})
                    <span *ngIf="contact.type">- {{contact.type}}</span>
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Asunto</mat-label>
            <input autocomplete="off" formControlName="subject" matInput>
        </mat-form-field>
        
        <div *ngIf="data.docs">
            <input #fileInput style="display: none" type="file"
                accept="image/png, image/jpeg, image/jpg,.doc, .docx,.txt,.pdf" (change)="onFileSelected($event)">
            <button mat-stroked-button color="primary" (click)="fileInput.click()">
                <mat-icon style="vertical-align: middle;">attach_file</mat-icon> ADJUNTAR ARCHIVO
            </button>
            <div style="margin-bottom: 8px;"><small>Solo se aceptan Word, Pdf, Png, Jpg y Jpeg</small></div>
        </div>
         
        <div *ngIf="selectedFile.length" style="margin-bottom: 5px;" class="flex flex-row flex-wrap justify-start items-center content-center">
            <div class="archivo" *ngFor="let file of selectedFile; let i = index">
                <mat-icon class="mini-icon">description</mat-icon>
                <span class="filename">{{file.name}}</span>
                <mat-icon class="mini-icon" style=" cursor: pointer;" (click)="removeFile(i)">
                    cancel
                </mat-icon>
            </div>
        </div>
        <quill-editor 
             [styles]="editorStyle"
             formControlName="message"
             [modules]="config"
             >
        </quill-editor>
    </form>
     
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" type="button" mat-dialog-close>Cancelar</button>
    <button mat-raised-button color="primary" (click)="send()" type="button" [disabled]="disabledButton()">
        <i class="fa fa-spinner fa-spin" *ngIf="buttonSubmit" aria-hidden="true"></i>Enviar
    </button>
</mat-dialog-actions>