import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class PucpUtility {

    private is_PUCPenv: boolean;
    private pucp: boolean = environment.pucp;

    constructor() {
        this.is_PUCPenv = this.pucp;
    }

    get inPucp(): boolean {
        return this.is_PUCPenv;
    }
}
