import {Component, Input, Output, EventEmitter} from '@angular/core';
import {UserService} from '../../core/services/api/user.service';
import {MediaPlanService} from '../../core/services/api/media-plan.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
    selector: 'app-detail-kanban',
    templateUrl: './detail-kanban.component.html',
    styleUrls: ['./detail-kanban.component.scss']
})
export class DetailKanbanComponent {
    @Input() row: any;
    @Input() detail: boolean;
    @Input() check: boolean;

    @Output() outDialog = new EventEmitter<any>();

    sending = -1;

    constructor(
        private dialog: MatDialog,
        private mediaPlanService: MediaPlanService,
        public userService: UserService
    ) { }

    openDialog() {
        this.outDialog.emit(this.row);
    }

}
