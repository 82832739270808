import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Api } from "../../contracts/classes/api.class";
import { CreateStageDTO, Stage } from "../../contracts/models/stages.model";
import { IResponse } from "../../contracts/models/response.model";

@Injectable({
  providedIn: "root"
})
export class StagesService extends Api {

  getAll(): Observable<IResponse<Stage[]>> {
    return this.http.get<IResponse<Stage[]>>(`${this.url}/faseMantenimiento`);
  }

  create(createStageDTO: CreateStageDTO): Observable<IResponse<Stage>> {
    return this.http.post<IResponse<Stage>>(`${this.url}/faseMantenimiento`, createStageDTO);
  }

  update(updateStageDTO: Stage): Observable<IResponse<Stage>> {
    return this.http.put<IResponse<Stage>>(`${this.url}/faseMantenimiento/${updateStageDTO.id}`, updateStageDTO);
  }

  delete(id: number): Observable<IResponse<Stage>> {
    return this.http.delete<IResponse<Stage>>(`${this.url}/faseMantenimiento/${id}`);
  }
}