import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CustomerService} from 'src/app/core/services/api/customer.service';

import {AsignarEncargadosFormComponent} from '../form/asignar-encargados-form.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ClienteEncargados } from 'src/app/core/contracts/models/cliente-encargados';

@Component({
    selector: 'app-asignar-encargados-list',
    templateUrl: './asignar-encargados-list.component.html',
    styleUrls: ['./asignar-encargados-list.component.scss']
})
export class AsignarEncargadosListComponent implements OnInit {

    @Input() id: any;
    rows: ClienteEncargados[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    isLoading = false;
    first = 1;

    addPermission = this.auth.isPermit('cliente.encargado.add');
    editPermission = this.auth.isPermit('cliente.encargado.edit');
    deletePermission = this.auth.isPermit('cliente.encargado.delete');
    constructor(
        public dialog: MatDialog,
        private auth: AuthService,
        private customerService: CustomerService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.isLoading = true;
        this.customerService.getAllClienteEncargados(this.id).subscribe((response: any) => {
            const {clienteEncargados} = response;
            this.rows = clienteEncargados.map(pr => {
                const cat = [pr.encargado.emails.length, pr.encargado.telefonos.length];
                const height = Math.max(...cat);
                pr['height'] = height ? 50 + ((height - 1) * 20) : 50;
                return pr;
            });
            this.isLoading = false;
            if (this.first != 1) {
                setTimeout(() => {
                    window.dispatchEvent(new Event('resize'));
                }, 200);
            }
            this.first++;
        });
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarEncargadosFormComponent, {
            data: {row, id: Number(this.id)},
            width: '480px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.customerService.deleteClienteEncargados(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }

    getRowHeight(row: any) {
        return row ? row.height : 0;
    }
}
