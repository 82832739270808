<div class="flex flex-row flex-wrap justify-start items-center content-center" style="margin-top: 10px; width: 100%;">
    <div class="flex flex-row flex-wrap justify-between items-center content-center px-4 w-full">
        <span>{{ label }}</span>
        <mat-checkbox color="primary"
            *ngIf="multiple"
            (change)="toggleAllPlatforms($event)"
            [checked]="isAllSelected()">
            Seleccionar todo
        </mat-checkbox>
    </div>
    <mat-form-field class="px-4 w-full">
        <mat-select 
            (selectionChange)="selectionChange()" 
            [formControl]="selection" 
            disableOptionCentering 
            [multiple]="multiple"
        >
            <mat-option *ngFor="let value of options" [value]="value.id">
                {{value.label}}
            </mat-option>
        </mat-select>
        <mat-error>Campo Requerido</mat-error>
    </mat-form-field>

</div>