import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/core/services/api/campaigns.service';
import { SpokesmenService } from 'src/app/core/services/api/spokesmen.service';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { NewPersonComponent } from '../../new-person/new-person.component';
import { combineLatest, Observable, of } from 'rxjs';
import { PeopleService } from '../../../core/services/api/people.service';
import { map, tap, debounceTime, distinctUntilChanged, switchMap, filter } from 'rxjs/operators';

@Component({
    selector: 'app-asignar-voceros-form',
    templateUrl: './asignar-voceros-form.component.html',
    styleUrls: ['./asignar-voceros-form.component.scss']
})
export class AsignarVocerosFormComponent implements OnInit {
    public loading: boolean = false;

    myForm = this.formBuilder.group({
        vocero_id: new FormControl<number | null>({ value: null, disabled: true }, [Validators.required]),
        filter: new FormControl<string | null>(null),
        observacion: new FormControl<string | null>(null),
        vinculo: new FormControl<string | null>(null)
    });

    vocero: any = {
        campaign_id: null,
        idVocero: null,
        observacion: null,
        vinculo: null
    }

    allVoceros: any[] = [];
    filteredVoceros: any[] = [];

    title = 'Agregar vocero';

    select$: Observable<any>

    filteredVoceros$: Observable<any[]>;

    constructor(
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarVocerosFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: any, id: number, idCliente: number },
        private campaignsService: CampaignsService,
        private spokesmenService: SpokesmenService,
        private swal: Swal2,
        private peopleService: PeopleService
    ) {
    }

    ngOnInit(): void {
        if (this.data.row) {
            this.title = 'Editar vocero';
            this.allVoceros = [this.data.row.vocero];
            this.filteredVoceros = [...this.allVoceros];
            this.myForm.controls.vocero_id.setValue(this.data.row.idVocero);
            this.myForm.controls.observacion.setValue(this.data.row.observacion);
            this.myForm.controls.vinculo.setValue(this.data.row.vinculo);
        } else {
            this.loadVoceros();
        }

        this.setupFilteredVoceros();
    }

    setupFilteredVoceros() {
        this.filteredVoceros$ = this.myForm.get('filter').valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged(),
            filter(value => typeof value === 'string'),
            switchMap(value => {
                if (!value || value.length < 2) {
                    return of([...this.allVoceros.slice(0, 5), this.selectedVocero()].filter(v => v));
                }
                return this.searchVoceros(value);
            })
        );
    }

    selectedVocero() {
      const idVocero = this.myForm.controls.vocero_id.value;
      return this.allVoceros.find(v => v.id === idVocero) || null;
    }

    searchVoceros(value: string): Observable<any[]> {
        return of(this.allVoceros.filter(v => 
            v.completeName.toLowerCase().includes(value.toLowerCase())
        ).slice(0, 5));
    }

    loadVoceros() {
        combineLatest([
            this.spokesmenService.getListByCliente(this.data.idCliente),
            this.peopleService.getAllSelect()
        ]).pipe(
            map(([voceros, personas]) => {
                const clientesVoceros = voceros['clienteVoceros'].map(cl => cl.vocero);
                const contacts = personas['contactos'];
                return contacts.map(ct => {
                    ct.isVoceroCliente = !!clientesVoceros.find(vc => vc.id == ct.id);
                    ct.completeName = `${ct.nombres} ${ct.apellidos}`;
                    return ct;
                }).sort((a, b) => {
                    const typeA = a.tiposPersona ? a.tiposPersona.split(',').map(item => Number(item)) : [0];
                    const typeB = b.tiposPersona ? b.tiposPersona.split(',').map(item => Number(item)) : [0];
                    return Math.max(...typeB) - Math.max(...typeA);
                }).sort((a, b) => b.id - a.id);
            })
        ).subscribe(response => {
            this.allVoceros = response;
            this.filteredVoceros = this.allVoceros.slice(0, 5);
            this.myForm.controls.vocero_id.enable();
        });
    }

    filterVoceros(value: string | null) {
        if (!value) {
            this.filteredVoceros = this.allVoceros.slice(0, 5);
        } else {
            this.filteredVoceros = this.allVoceros.filter(v => 
                v.completeName.toLowerCase().includes(value.toLowerCase())
            );
        }
    }

    onSubmit() {
        this.loading = true;
        this.vocero = {
            campaign_id: this.data.id,
            idVocero: this.myForm.controls.vocero_id.value,
            observacion: this.myForm.controls.observacion.value,
            vinculo: this.myForm.controls.vinculo.value
        }
        let observable: Observable<any> | undefined = undefined
        
        if (this.data.row) {
            this.vocero.id = this.data.row.id;
            observable = this.campaignsService.editCampaignVoceros(this.vocero)
        } else {
            observable = this.campaignsService.createCampaignVoceros(this.vocero)
        }
        observable.subscribe((response: any) => {
            this.dialogRef.close(response);
            this.loading = false;
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.status === 400) {
                this.error(error);
            }
            this.loading = false;
        });
    }

    changeVocero() {
        if (!!this.myForm.controls.vocero_id.value && this.myForm.controls.vocero_id.value != -1) {
            const person = this.allVoceros.find(el => el.id == this.myForm.controls.vocero_id.value);
            const ind = this.allVoceros.findIndex(el => el.id == this.myForm.controls.vocero_id.value);
            if (!person.isVoceroCliente) {
                this.dialog.open(NewPersonComponent, {
                    data: {
                        tipo: [2],
                        idCliente: this.data.idCliente,
                        edit: !person.tiposPersona.includes('2'),
                        person
                    },
                    width: '600px'
                }).afterClosed().subscribe(res => {
                    if (res) {
                        res.completeName = res.nombres + ' ' + res.apellidos;
                        this.allVoceros[ind] = res;
                        this.myForm.controls.vocero_id.setValue(res.id);
                        this.filterVoceros(this.myForm.get('filter')?.value);
                    } else {
                        this.myForm.controls.vocero_id.setValue(null);
                    }
                })
            }
        }
        if (this.myForm.controls.vocero_id.value == -1) {
            this.newPerson();
        }
    }

    error(error: any) {
        if (error.error.errors.idVocero) {
            this.swal.showToast(error.error.errors.idVocero[0], 'error');
        }
    }

    newPerson() {
        this.dialog.open(NewPersonComponent, {
            data: { tipo: [2], idCliente: this.data.idCliente },
            width: '600px'
        }).afterClosed().subscribe(res => {
            if (res) {
                res.completeName = res.nombres + ' ' + res.apellidos;
                this.allVoceros.push(res);
                this.myForm.controls.vocero_id.setValue(res.id);
                this.filterVoceros(this.myForm.get('filter')?.value);
            } else {
                this.myForm.controls.vocero_id.setValue(null);
            }
        });
    }
}