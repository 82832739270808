<div *ngFor="let rate of rateCampaign;let i = index">
    <div class="title-div title-div--bold">{{rate.name}}</div>

    <div class="px-0 py-4 grid md:grid-cols-3 gap-x-4 gap-y-2">

        <div *ngFor="let rt of rate.rates;let k = index">
            <div class="grid grid-cols-[3fr_1fr] gap-x-8">
                <div>
                    <label for="mat-slider" class="w-full  whitespace-nowrap" style="font-size: 0.9em;">{{rt.title}}</label>
                    <!-- TODO: The 'tickInterval' property no longer exists -->
                    <!-- <mat-slider name="mat-slider" thumbLabel min="0" max="5" style="width: 100%;" color="primary" [(ngModel)]="rt.value"
                        (ngModelChange)="onChange()"><input matSliderThumb />
                    </mat-slider> -->
                    <mat-slider class="!w-full" max="5" min="0" step="1" [discrete]="true" [showTickMarks]="true">
                        <input matSliderThumb [(ngModel)]="rt.value">
                    </mat-slider>
                </div>
                <mat-form-field class="w-[60px]" >
                    <input matInput type="number" [min]="0" [max]="5" autocomplete="off" [(ngModel)]="rt.value"
                        (ngModelChange)="onChange()" (keypress)="onlyNumber($event,rt.value)">
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<mat-divider></mat-divider>
<div class="title-div title-div--bold">Campaña</div>

<div  class="grid md:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-2">
    <mat-form-field class="w-full" *ngFor="let cm of campaignValor;let in = index">
        <mat-label>{{cm.title}}</mat-label>
        <mat-select [(ngModel)]="cm.value" (ngModelChange)="onChange()">
            <mat-option *ngFor="let opt of cm.options" [value]="opt.id">{{opt.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>