import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SpokesmenService} from 'src/app/core/services/api/spokesmen.service';

import {AsignarClientesFormComponent} from '../form/asignar-clientes-form.component';
import {take} from 'rxjs/operators';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ClienteVoceros } from 'src/app/core/contracts/models/cliente-voceros';

@Component({
    selector: 'app-asignar-clientes-list',
    templateUrl: './asignar-clientes-list.component.html',
    styleUrls: ['./asignar-clientes-list.component.scss']
})
export class AsignarClientesListComponent implements OnInit {

    @Input() id: any;
    rows: ClienteVoceros[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    addPermission = this.auth.isPermit('persona.cliente.add');
    editPermission = this.auth.isPermit('persona.cliente.edit');
    deletePermission = this.auth.isPermit('persona.cliente.delete');

    constructor(
        public dialog: MatDialog,
        private auth: AuthService,
        private spokesmenService: SpokesmenService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.spokesmenService.getAllClienteVoceros(this.id).subscribe((response: any) => {
            const {clienteVoceros} = response;
            this.rows = clienteVoceros;
        });
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarClientesFormComponent, {
            data: {row, id: Number(this.id)},
            width: '500px'
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.spokesmenService.deleteClienteVoceros(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }
}
