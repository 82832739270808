import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PucpUtility } from 'src/app/core/utils/pucp.util';

const alwaysVisibleLinks = ['all', 'kanban'];

enum LINK_TYPE {
  link = 'link',
  tree = 'tree'
}

export interface SidebarRoute {
  label: string;
  slug: string[];
  link: string;
}

export interface SidebarLink extends SidebarRoute {
  type: LINK_TYPE.link;
  icon: string;
}

export interface SidebarTreeLink extends Omit<SidebarRoute, 'link'> {
  type: LINK_TYPE.tree;
  icon: string;
  tree: SidebarRoute[];
}

export const sidebarLinks: Array<SidebarLink | SidebarTreeLink> = [
  {
    type: LINK_TYPE.link,
    icon: 'home',
    label: 'Inicio',
    slug: ['all'],
    link: 'home'
  },
  {
    type: LINK_TYPE.tree,
    icon: 'web',
    label: 'Administrador',
    slug: ['user.list', 'role.list', 'tipo-cambio.list', 'clipping.list','valorizaciones.list'],
    tree: [
      { label: 'Usuarios', slug: ['user.list'], link: 'admin/users' },
      { label: 'Roles', slug: ['role.list'], link: 'admin/roles' },
      { label: 'Tipo de cambio', slug:['tipo-cambio.list'], link: 'admin/rate' },
      { label: 'Clipping', slug:['clipping.list'], link: 'admin/clipping' },
      { label: 'Valorizaciones', slug: ['valorizaciones.list'], link: 'admin/valorizaciones' },
      { label: 'Actividad de usuarios', slug: ['activity'], link: 'admin/user-activity/list' }
      
    ]
  },
  {
    type: LINK_TYPE.tree,
    icon: 'cloud',
    label: 'Gestión',
    slug: ['persona.list', 'medio.list', 'cliente.list', 'campaign.list', 'nota-prensa.list', 'plan-medio.list'],
    tree: [
      { label: 'Personas', slug: ['persona.list'], link: 'database/people' },
      { label: 'Medios', slug: ['medio.list'], link: 'database/media' },
      { label: 'Clientes', slug: ['cliente.list'], link: 'database/customers' },
      { label: 'Campañas', slug: ['campaign.list'], link: 'database/campaigns' },
      { label: 'Plan de medios', slug: ['plan-medio.list'], link: 'database/media-plan' },
      { label: 'Notas de prensa', slug: ['nota-prensa.list'], link: 'database/press-release' },
    ]
  },
  {
    type: LINK_TYPE.tree,
    icon: 'description',
    label: 'Reportes',
    slug: ['reporte.generar-reporte', 'reporte.impactos', 'reporte.grafico-impactos', 'reporte.estado'],
    tree: [
      { label: 'Generar reporte', link: 'reports/generate-report', slug: ['reporte.generar-reporte'] },
      { label: 'Impactos por plataformas', link: 'reports/platform-impacts', slug: ['reporte.impactos'] },
      { label: 'Grafico impactos por plataformas', link: 'reports/graph-impacts-by-platforms', slug: ['reporte.grafico-impactos'] },
      { label: 'Estado por campaña o vocero', link: 'reports/status-by-campaign-or-spokesperson', slug: ['reporte.estado'] }
    ]
  },
  {
    type: LINK_TYPE.tree,
    icon: 'storage',
    label: 'Sistema experto',
    slug: ['sistema-experto.casos.list', 'sistema-experto.analisis'],
    tree: [
      { label: 'Análisis estratégico', link: 'expert-system/analisis', slug: ['sistema-experto.analisis'] },
      { label: 'Envío de casos', link: 'expert-system/list', slug: ['sistema-experto.casos.list'] }
    ]
  },
  {
    type: LINK_TYPE.link,
    icon: 'build',
    label: 'Mantenimiento',
    slug: ['mantenimiento'],
    link: 'maintenance'
  },
  {
    type: LINK_TYPE.link,
    icon: 'view_column',
    label: 'Kanban',
    slug: ['kanban'],
    link: 'kanban'
  },
];

@Component({
  selector: 'adp-sidebar-links',
  templateUrl: './sidebar-links.component.html',
  styleUrls: ['./sidebar-links.component.scss']
})
export class SidebarLinksComponent {
    private auth = inject(AuthService);
    private pucp = inject(PucpUtility);
    private router = inject(Router);

    public links : Array<SidebarLink | SidebarTreeLink> = [];

    constructor(){
      this.links = this.filterLinksByPermissions(sidebarLinks, this.auth.isPermitMenu());
    }

    private filterLinksByPermissions(links: Array<SidebarLink | SidebarTreeLink>, permissions: string[]): Array<SidebarLink | SidebarTreeLink> {  
      return links
        .map(link => {
          const transformedLink = {...link, label: this.capitalizeFirstLetter(link.label)};
          if (transformedLink.type === LINK_TYPE.tree) {
            if(this.pucp.inPucp && transformedLink.label == 'Sistema experto') {
              return null;
            }
            const filteredTree = transformedLink.tree.map(item => ({
              ...item, 
              label: this.capitalizeFirstLetter(item.label)
            })).filter(item => permissions.includes(item.slug[0]));  
            return filteredTree.length > 0 ? { ...transformedLink, tree: filteredTree } : null;
          }
          return permissions.includes(transformedLink.slug[0]) || alwaysVisibleLinks.includes(transformedLink.slug[0]) ? transformedLink : null;
        })
        .filter(link => link !== null) as Array<SidebarLink | SidebarTreeLink>;
    }

    private capitalizeFirstLetter(string: string): string {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
}