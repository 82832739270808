import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({providedIn: 'root'})
export class Swal2 {

    confirmButtonColor:string = '#f33';
    cancelButtonColor:string = '#999';

    public showToast(title: string, icon: SweetAlertIcon = 'success') {
        Swal.fire({
            icon,
            title,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
        });
    }

    showAlert(title: string, icon: SweetAlertIcon = 'success') {
        Swal.fire({
        text: title,
        icon: icon,
        heightAuto: false,
        confirmButtonColor: this.confirmButtonColor,
        });
    }

    returnAlert(title: string, icon: SweetAlertIcon = 'success') {
        return Swal.fire({
            text: title,
            icon: icon,
            heightAuto: false,
            confirmButtonColor: this.confirmButtonColor,
        });
    }

    showTitleAlert(title: string, text?: string, icon: SweetAlertIcon = 'success') {
        Swal.fire({
        title: title,
        text: text,
        icon: icon,
        heightAuto: false
        });
    }

    showErrorAlert(message: string, title:string = 'Error', errors:any = null) {
        let errorsDetail = ''
        if(errors == null){ errorsDetail = ''}
        else{
        for (const err in errors){
            const v = err.split('.')
            const str = errors[err][0].replace(err,  `contacto N°${parseInt(v[1]) + 1}`)
            errorsDetail += `<li>${str}</li>`
        };
        errorsDetail = `<div><p><b>Se Presentaron los siguientes Errores:</b></p><ul>${errorsDetail}</ul></div>`
        }
        Swal.fire({
        title: title,
        text: message,
        icon: 'error',
        heightAuto: false,
        footer:errorsDetail
        })
    }

    deleteItem() {
        return new Promise(resolve => {
        Swal.fire({
            title: '¿Está seguro que desea borrar el siguiente registro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: this.confirmButtonColor,
            cancelButtonColor: this.cancelButtonColor,
            confirmButtonText: 'Si',
            cancelButtonText: 'Cancelar',
            heightAuto: false
        }).then((result) => {
            if (result.value) {
            resolve(true);
            } else {
            resolve(false);
            }
        });

        });
    }

    desactiveItem(message:string = '', title:string = '') {
        return new Promise(resolve => {
        Swal.fire({
            title: title,
            text: message,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: this.confirmButtonColor,
            cancelButtonColor: this.cancelButtonColor,
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Volver',
            heightAuto: false
        }).then((result) => {
            if (result.value) {
            resolve(true);
            } else {
            resolve(false);
            }
        });

        });
    }

    loading() {
        // no sabria cerrar
    }

    saveChanges(): Promise<SweetAlertResult<any>> {
        return Swal.fire({
            title: 'Quieres cambiar los cambios?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Guardar',
            denyButtonText: `No guardar`,
            confirmButtonColor: this.confirmButtonColor,
            cancelButtonColor: this.cancelButtonColor,
        })   
    }

    /**
     * Alerta para los errores HTTP que resulten de las consultas API
     * @param code 
     * @param html 
     * @param icon 
     */
    public httpErrorAlert(code: number, html: string, icon: SweetAlertIcon = 'error') {
        Swal.fire({
            icon,
            title: `<strong>Error HTTP ${code}</strong>`,
            html,
            confirmButtonColor: this.confirmButtonColor,            
            confirmButtonText: 'Continuar',
        });
    }

    /**
     * Alerta para los errores HTTP que resulten de las consultas API
     * @param code 
     * @param html 
     * @param icon 
     */
    public http504ErrorAlert(html: string) {
        Swal.fire({
            icon: 'warning',
            title: `<strong> Verifica tu conexión a Internet</strong>`,
            html,
            confirmButtonColor: this.confirmButtonColor,            
            confirmButtonText: 'Continuar',
        });
    }

    public confirmDelete(): Promise<boolean> {
        return Swal.fire({
            title: '¿Está seguro que desea borrar el siguiente registro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: this.confirmButtonColor,
            cancelButtonColor: this.cancelButtonColor,
            confirmButtonText: 'Si',
            cancelButtonText: 'Cancelar',
            heightAuto: false
        }).then(result => result.isConfirmed);
    }
}
