<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form [formGroup]="myForm" class="grid gap-y-2">
        <div class="grid sm:grid-cols-2 gap-x-4 gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Nombres</mat-label>
                <input autocomplete="off" formControlName="name" type="text" matInput>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Apellidos</mat-label>
                <input autocomplete="off" formControlName="lastName" type="text" matInput>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="grid sm:grid-cols-2 gap-x-4 gap-y-2">
            <div class="w-full ">
                <label for="mat-radio-group" id="example-radio-group-label">Género</label>
                <mat-radio-group name="mat-radio-group" aria-labelledby="example-radio-group-label"
                    class="flex flex-row flex-wrap" style="margin: 5px 0;" color="primary" formControlName="sex"
                    required>
                    <mat-radio-button [value]="0" style="margin: 5px;">Femenino</mat-radio-button>
                    <mat-radio-button [value]="1" style="margin: 5px;">Masculino</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="isFieldValid('sex')">
                    Completa este campo.
                </mat-error>
            </div>
            <mat-form-field class="w-100" *ngIf="data.idCliente">
                <mat-label>Cargo Vocero</mat-label>
                <input matInput type="text" autocomplete="off" formControlName="cargo">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-100 " *ngIf="!data.idCliente">
                <mat-label>Tipo</mat-label>
                <mat-select multiple formControlName="tipos">
                    <mat-option [value]="1" [disabled]="contacto">Contacto</mat-option>
                    <mat-option [value]="2">Vocero</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="grid sm:grid-cols-2 gap-y-2 gap-x-4 mb-2 " *ngIf="contacto">
            <mat-form-field class="w-full">
                <mat-label>Categorías</mat-label>
                <mat-select disableOptionCentering multiple formControlName="categories" placeholder="Seleccione">
                    <mat-select-trigger>
                        <mat-chip-listbox class="multi">
                            <mat-basic-chip class="chipName" *ngFor="let ct of selectCat$|async" [removable]="true">
                                <mat-icon matChipRemove>close</mat-icon>
                                <span>{{ct.descripcion}}</span>
                            </mat-basic-chip>
                        </mat-chip-listbox>
                    </mat-select-trigger>
                    <mat-option *ngIf="categories.length>4">
                        <ngx-mat-select-search formControlName="filterC" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let ct of categories| search: myForm.get('filterC').value:['descripcion']"
                        [value]="ct.id">
                        {{ct.descripcion}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Temas de interés</mat-label>
                <mat-select multiple formControlName="temas">
                    <mat-option *ngFor="let t of temas" [value]="t.id">{{t.descripcion}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div *ngIf="!vocero">
        <hr style="margin-bottom: 15px;">
        <div class="w-full flex flex-row flex-wrap justify-between items-center content-center">
            <h3 style="font-size: 16px;">Correo</h3>
            <button type="button" mat-button mat-button-sm color="primary" class="mb-4" (click)="addEmail()">
                Agregar correo
            </button>
        </div>
        <div *ngFor="let group of emailsForm.controls; let i = index">
            <form [formGroup]="group">
                <div class="grid sm:grid-cols-[1fr_2fr_auto] gap-x-4 gap-y-2">
                    <mat-form-field class="w-full">
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="idTipoEmail" name="idTipoEmail">
                            <mat-option *ngFor="let e of typeEmails" [value]="e.id">{{e.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Correo</mat-label>
                        <input autocomplete="off" formControlName="email" type="text" matInput>
                        <mat-error *ngIf="group.get('email').errors?.['required']">
                            Completa este campo.
                        </mat-error>
                        <mat-error *ngIf="group.get('email').errors?.['email']">
                            Introduzca un correo valido.
                        </mat-error>
                    </mat-form-field>
                    <button mat-stroked-button mat-button-sm type="button" class="w-max relative -top-1 sm:top-3"  (click)="deleteEmail(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </form>
        </div>
        <hr style="margin-bottom: 15px;">
        <div class="w-full flex flex-row flex-wrap justify-between items-center content-center">
            <h3 style="font-size: 16px;">Teléfono</h3>
            <button type="button" mat-button mat-button-sm color="primary" class="mb-4" (click)="addPhone()">
                Agregar teléfono
            </button>
        </div>
        <div *ngFor="let group of phonesForm.controls; let i = index">
            <form [formGroup]="group">
                <div class="grid sm:grid-cols-[1fr_2fr_auto] gap-x-4 gap-y-2">
                    <mat-form-field class="w-full">
                        <mat-label>Tipo</mat-label>
                        <mat-select formControlName="idTipoTelefono" name="idTipoTelefono">
                            <mat-option *ngFor="let p of typePhones" [value]="p.id">{{p.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="w-full">
                        <mat-label>Número</mat-label>
                        <input matInput type="phone" autocomplete="off" formControlName="telefono">
                        <mat-error>
                            <span *ngIf="group.get('telefono').errors?.['required']"> Completa este campo.</span>
                            <span *ngIf="group.get('telefono').errors?.['max']length"> Máximo 25 dígitos</span>
                            <span *ngIf="group.get('telefono').errors?.['pattern']"> Caracteres inválidos</span>
                        </mat-error>
                    </mat-form-field>
                    <button mat-stroked-button mat-button-sm type="button"  class="w-max relative -top-1 sm:top-3" (click)="deletePhone(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>
