<div class="box">

    <div class="info-box" (click)="openDialog()">
        <div class="lj ellipsis">{{row.contactName}}</div>

        <div class="ellipsis">
            <b>{{row.programa_contacto.programa.medio.nombre}}: </b> {{row.programa_contacto.programa.nombre}}
        </div>
        <div class="flex flex-row flex-wrap justify-between items-center content-center" *ngIf="detail">
            <div> {{ row.typeNoteName}}</div>
            <div class="flex flex-row flex-wrap justify-start items-end content-end">
                <div *ngFor="let p of row.medioPlataformas" class="div-plat" 
                    [ngClass]="{
                        'badge-primary': p.plataforma_clasificacion.plataforma.id === 5, 
                        'badge-success': p.plataforma_clasificacion.plataforma.id === 3, 
                        'badge-warning': p.plataforma_clasificacion.plataforma.id === 9,
                        'badge-info':    p.plataforma_clasificacion.plataforma.id === 1,
                        'badge-danger':  p.plataforma_clasificacion.plataforma.id === 2
                    }"
                >
                </div>
            </div>
        </div>

    </div>
    <div  style="display: flex; flex-wrap: wrap;align-items:center;border-top:1px solid #ebe8e8; padding: 3px 7px;">
        <div>
            <mat-icon class="mini-icon">forum</mat-icon> {{row.comments}}
        </div>
        <div class="sample"> 
            <mat-icon class="mini-icon" *ngIf="row.sendedEmails;" color="accent" [matTooltip]="row.sendedEmails.join(', ')"> email </mat-icon>
        </div>
        <div class="sample" *ngIf="row.muestrasRegistradas" matTooltip="Muestras">
            <mat-icon class="mini-icon">library_books</mat-icon> {{row.muestrasRegistradas}}
        </div>
        <div class="sample links" *ngIf="row.hasAssociated" matTooltip="Tiene vinculados">
            <mat-icon class="mini-icon">link</mat-icon>
        </div>
        <div class="sample" *ngIf="!row.voceros.length" matTooltip="Sin Voceros">
            <mat-icon style="color: #ff3333;" class="mini-icon">group_off</mat-icon>
        </div>
        <div class="sample etiqueta linked" *ngIf="row.idDetallePlanMedioPadre">
            Vinculado
        </div>
        <span style="flex: 1 1;"></span>
        <div class="sample etiqueta transfer" *ngIf="row.isTransfer">
            Transferido
        </div>
        <ng-content select="[slot=actions]"></ng-content>

    </div>
    <div class="edge" *ngIf="check">
        <ng-content select="[slot=check]"></ng-content>
    </div>
</div>