import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class Storage {

  constructor() { }

  public setItem( key:string, value: object | string | number ): void {
    try {
      localStorage.setItem( key, JSON.stringify(value) );
    } catch (error) {
      console.error('Error al guardar en localStorage:', error);
    }
  }

  public getItem<T = any>( key:string ): T | null {
    try {
      const serializedData = localStorage.getItem(key);
      if (serializedData === null) { return null; }
      return JSON.parse(serializedData) as T;
    } catch (error) {
      console.error('Error al obtener de localStorage:', error);
      return null;
    }

  }
}
