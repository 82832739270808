import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Api } from '../../contracts/classes/api.class';
import { Media } from '../../contracts/models/media.interface';

@Injectable({ providedIn: 'root' })
export class MediaService extends Api{

  getAll() {
    return this.http.get(`${this.url}/medios`);
  }

  getList() {
    return this.http.get(`${this.url}/medios/data/getList`);
  }

  getListSelect() {
    return this.http.get(`${this.url}/medios/select`);
  }

  getId(id: number) {
    return this.http.get(`${this.url}/medios/${id}`).pipe(
      map((response: any) => {
        return response.medio;
      })
    );
  }

  create(data: Media) {
    return this.http.post(`${this.url}/medios`, data);
  }

  edit(data: Media) {
    return this.http.put(`${this.url}/medios/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/medios/${id}`);
  }
}
