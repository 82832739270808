<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form [formGroup]="myForm" class="grid gap-y-2">
        <mat-form-field class="w-full">
            <mat-label>Representante</mat-label>
            <mat-select disableOptionCentering (selectionChange)="newPerson()" formControlName="persona_id" required>
                <mat-option>
                    <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                        noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="-1">Agregar Nuevo</mat-option>
                <mat-option *ngFor="let p of personas| search: myForm.get('filter').value:['completeName']"
                    [value]="p.id">{{p.nombres}} {{p.apellidos}}</mat-option>
            </mat-select>
            <mat-error>
                Completa este campo.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Cargo</mat-label>
            <mat-select formControlName="tipoEncargado" required>
                <mat-option [value]="1">Propietario</mat-option>
                <mat-option [value]="2">Representante legal</mat-option>
                <mat-option [value]="3">Coordinador de Marketing</mat-option>
                <mat-option [value]="4">Coordinador de comunicación</mat-option>
            </mat-select>
            <mat-error>
                Completa este campo.
            </mat-error>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>