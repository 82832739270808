import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Select } from '../../core/contracts/models/form-input.model';

/**
 * @description Falta implementar en el componente la posibilidad que cargue datos iniciales
 */
@Component({
  selector: 'multiple-select-all',
  templateUrl: './multiple-select-all.component.html',
  styleUrls: ['./multiple-select-all.component.scss']
})
export class MultipleSelectAllComponent {

  
  @Input() public label: string;
  @Input() public options: Select[] = [];
  @Input() public multiple: boolean = true;
  @Output() public selectedEvent = new EventEmitter<number[]>();

  public selection: FormControl = new FormControl<number[]>([], Validators.required);

  constructor( ) { }

  public isSelected( id: number): boolean {
    return this.selection.value.includes(id)
  }

  public isAllSelected() {
    const condition_1 = this.selection.value.length > 0;
    const condition_2 = this.selection.value.length === this.options.length;
    return condition_1 && condition_2;
  }

 public toggleAllPlatforms(event: MatCheckboxChange): void {
  event.checked 
    ? this.selection.setValue(this.options.map( option => option.id )) 
    : this.selection.setValue([]);
  this.selectedEvent.emit(this.selection.value);
  }

  public selectionChange(){
    this.selectedEvent.emit(this.selection.value);
  }

}
