import { Injectable, inject } from "@angular/core";
import { 
    HttpInterceptor, 
    HttpRequest, 
    HttpHandler, 
    HttpEvent 
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth/auth.service";

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {
    private auth = inject(AuthService);

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isLoggedIn = Boolean(this.auth.user) && Boolean(this.auth.getTokenValue());
        
        if (isLoggedIn) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.auth.getTokenValue()}`,
                    Accept: 'application/json'
                }
            });
        }

        return next.handle(request);
    }
}