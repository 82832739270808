import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Select } from 'src/app/core/contracts/models/form-input.model';
import { ReplaySubject, Subject } from 'rxjs';
import {debounceTime, tap, filter, takeUntil, switchMap} from 'rxjs/operators';
import { SearchBoxService } from '../../core/services/search-box.service';

@Component({
  selector: 'search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent implements OnInit, OnDestroy {

	public myForm: FormControl = new FormControl();
   	public filteringCtrl: FormControl = new FormControl();
 
   	public searching: boolean = false;
 
   	public  filteredServer: ReplaySubject<Select[]> = new ReplaySubject<Select[]>(1);
   	protected _onDestroy = new Subject<void>();
 
	@Input() public placeholder: string = 'Filtrar elemento';
	@Input() public empty_label: string = 'Sin Resultados';
	@Input() public search_placeholder: string = 'Buscar ...';
	@Input() public observer_diccionary: string;
	@Output() public selectedId = new EventEmitter<Select>();


   	constructor( private searchBoxService: SearchBoxService ) {}

	public ngOnInit() {
		this.filteringCtrl.valueChanges
		.pipe(
			debounceTime( 500 ),
			filter( search => !!search ),
			tap( () => this.searching = true ),
			takeUntil( this._onDestroy ),
			switchMap( search => this.searchBoxService.findObserver( this.observer_diccionary, search) ),
		).subscribe({
			next: filteredList => {
				this.searching = false;
				this.filteredServer.next( filteredList );
			},
			error: error => {
				this.searching = false;
			}
		})

		this.myForm.valueChanges.pipe( takeUntil( this._onDestroy ) )
		.subscribe( value => this.selectedId.emit(value))
	}
 
	public ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}
}
