import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TypesOfAddressesFormComponent} from '../form/types-of-addresses-form.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';

import {PlacesItems} from 'src/app/shared/general/places';
import { Address } from 'src/app/core/contracts/models/address.model';

@Component({
    selector: 'app-types-of-addresses-list',
    templateUrl: './types-of-addresses-list.component.html',
    styleUrls: ['./types-of-addresses-list.component.scss']
})
export class TypesOfAddressesListComponent implements OnInit {

    @Output() onAddresses = new EventEmitter<any>();
    @Input() data: any[] = [];
    @Input() district: boolean = false;
    messages = {emptyMessage: 'No hay información para mostrar.'};

    constructor(
        public dialog: MatDialog,
        private swal: Swal2,
        private places: PlacesItems
    ) {
    }

    ngOnInit(): void {
    }

    ngOnChanges() {
        if (this.district) {
            this.data = this.data.map(el => {
                el.distrito = el.ubigeo ? this.places.getName(el.ubigeo) : '---'
                return el
            })
        }
    }

    openDialog(row?: Address) {
        const dialogRef = this.dialog.open(TypesOfAddressesFormComponent, {
            data: {
                data: row,
                district: this.district
            },
            width: '700px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addAddress(result);
            }
        });
    }

    addAddress(address: Address) {
        const newadd = {
            ...address,
            distrito: address.ubigeo ? this.places.getName(address.ubigeo) : '---'
        }
        const rows = [...this.data];
        rows.push(newadd);
        this.data = rows;
        this.onAddresses.emit(rows);
    }

    editAddress(row: Address, id: number) {
        const rows = [...this.data];
        rows.splice(id, 1);
        this.data = rows;
        this.openDialog(row);
        this.onAddresses.emit(rows);
    }

    deleteAddress(id) {
        this.swal.deleteItem().then(val => {
            if (val) {
                const rows = [...this.data];
                rows.splice(id, 1);
                this.data = rows;
                this.onAddresses.emit(rows);
                this.swal.showTitleAlert('Eliminado', 'Registro eliminado con éxito', 'success');
            }
        });
    }
}
