import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CampaignsService } from 'src/app/core/services/api/campaigns.service';
import { format, addDays, differenceInDays } from 'date-fns';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav-rigth',
  templateUrl: './sidenav-rigth.component.html',
})
export class SidenavRigthComponent implements OnInit {
  public campaignList = [];
  public today: number = Date.now();
  @Output()closeNotifications =new EventEmitter<void>();

  constructor(
    private campaign: CampaignsService, 
    private router: Router,
   ) { }

  ngOnInit() {
    const send = {
      fechaInicio: format(this.today, 'yyyy-MM-dd'),
      fechaFin: format(addDays(this.today, 7), 'yyyy-MM-dd')
    }
    this.campaign.getCampaignByDate(send).subscribe(res => {
      this.campaignList = res['campaigns'].map(cm => {
        const end = new Date(cm.fechaFin)
        cm['isToday'] = differenceInDays(end, this.today)
        cm['open'] = false
        return cm
      }).sort((a, b) => a.isToday - b.isToday)
    })
  }

  public openNavSubMenu(cm: any) {
    cm.open = !cm.open
  }

  public gotToPlan(id: number) {
    this.router.navigate(['/database/media-plan/detalle', id]);
    this.closeNotifications.emit();
  }


}
