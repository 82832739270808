import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/core/services/api/campaigns.service';
import { CustomerService } from 'src/app/core/services/api/customer.service';
import { MediaPlanService } from 'src/app/core/services/api/media-plan.service';
import { PressReleaseService } from 'src/app/core/services/api/press-release.service';
import { Subscription } from 'rxjs';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Media } from 'src/app/core/contracts/models/media.interface';

@Component({
    selector: 'app-asignar-plan-medios-form',
    templateUrl: './asignar-plan-medios-form.component.html',
    styleUrls: ['./asignar-plan-medios-form.component.scss']
})
export class AsignarPlanMediosFormComponent implements OnInit, OnDestroy {
    public loading: boolean = false;

    myForm = this.formBuilder.group({
        cliente: new FormControl<number | null>({ value: null, disabled: true }, [Validators.required]),
        campania: new FormControl<number | null>({ value: null, disabled: true }, [Validators.required]),
        planMedios: new FormControl<number | null>({ value: null, disabled: true }, [Validators.required]),
        filter: new FormControl<string | null>(null),
        filterC: new FormControl<string | null>(null),
        filterP: new FormControl<string | null>(null),
    });

    title = 'Agregar plan de medios';
    formSubmitAttempt = false;

    campaigns: any[] = [];
    campaignsTemp: any[] = [];
    planMedios: any[] = [];
    planMediosTemp: any[] = [];
    clientes: Media[] = [];

    subscriptionFormCliente: Subscription;
    subscriptionFormCampania: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarPlanMediosFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: any, id: number },
        private customerService: CustomerService,
        private mediaPlanService: MediaPlanService,
        private campaignsService: CampaignsService,
        private pressReleaseService: PressReleaseService,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.customerService.getListSelect().subscribe((response: any) => {
            const { clientes } = response;
            this.clientes = clientes;
            this.myForm.controls.cliente.enable();
        });

        if (this.auth.isAgent$.value) {
            this.campaignsService.getByLogged().subscribe((response: any) => {
                const { campaigns } = response;
                this.campaignsTemp = campaigns;
            });

            this.mediaPlanService.getByLogged().subscribe((response: any) => {
                const { planMedios } = response;
                this.planMediosTemp = planMedios;
            });
        }

        this.subscriptionFormCliente = this.myForm.controls.cliente.valueChanges.subscribe(id => {
            if (id) {
                this.myForm.controls.campania.reset();
                this.campaignsService.getByCliente(id).subscribe(res => {
                    this.campaigns = res['campaigns'];
                    this.myForm.controls.campania.enable();
                })
            } else {
                this.myForm.controls.campania.setValue(null);
                this.myForm.controls.campania.disable();
            }
        });

        this.subscriptionFormCampania = this.myForm.get('campania').valueChanges.subscribe(id => {
            if (id) {
                this.myForm.controls.planMedios.reset();
                this.mediaPlanService.getByCampaign(id).subscribe((response: any) => {
                    const { planMedios } = response;
                    this.planMedios = planMedios;
                    this.myForm.controls.planMedios.enable();
                });


            } else {
                this.myForm.controls.planMedios.setValue(null);
                this.myForm.controls.planMedios.disable();
            }
        });

        if (this.data.row) {
        }
    }

    ngOnDestroy() {
        this.subscriptionFormCampania.unsubscribe();
        this.subscriptionFormCliente.unsubscribe();
    }

    onSubmit() {
        this.loading = true;
        this.formSubmitAttempt = true;
        this.pressReleaseService.associatePlanMedio(this.data.id, this.myForm.controls.planMedios.value).subscribe((response: any) => {
            this.dialogRef.close(this.myForm.value);
            this.loading = false;
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.status === 400) {
                this.error(error);
            }
            this.loading = false;
        })
    }

    error(error) {
        if (error.error.errors.id) {
            this.swal.showToast(error.error.errors.id[0], 'error');
        }
        if (error.error.errors.idPlanMedio) {
            this.swal.showToast(error.error.errors.idPlanMedio[0], 'error');
        }
    }

    clear(form) {
        this.myForm.get(form).setValue(null);
    }
}
