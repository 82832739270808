import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

import {MatDialog} from '@angular/material/dialog';
import {TypesOfEmailsFormComponent} from '../form/types-of-emails-form.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { Email } from 'src/app/core/contracts/models/email';

@Component({
    selector: 'app-types-of-emails-list',
    templateUrl: './types-of-emails-list.component.html',
    styleUrls: ['./types-of-emails-list.component.scss']
})
export class TypesOfEmailsListComponent implements OnInit {

    @Output() onEmails = new EventEmitter<any>();
    @Input() data: Email[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    constructor(
        public dialog: MatDialog,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
    }

    openDialog(row?: Email) {
        const dialogRef = this.dialog.open(TypesOfEmailsFormComponent, {
            data: row,
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addEmail(result);
            }
        });
    }

    addEmail(email: Email) {
        const rows = [...this.data];
        rows.push(email);
        this.data = rows;
        this.onEmails.emit(rows);
    }

    editEmail(row: Email, id: number) {
        const rows = [...this.data];
        rows.splice(id, 1);
        this.data = rows;
        this.openDialog(row);
        this.onEmails.emit(rows);
    }

    deleteEmail(id) {
        this.swal.deleteItem().then(val => {
            if (val) {
                const rows = [...this.data];
                rows.splice(id, 1);
                this.data = rows;
                this.onEmails.emit(rows);
                this.swal.showTitleAlert('Eliminado', 'Registro eliminado con éxito', 'success');
            }
        });
    }
}
