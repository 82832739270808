import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { Api } from "../../contracts/classes/api.class";
import { IResponse } from "../../contracts/models/response.model";
import { CreateTypePublicDTO, TypePublic, UpdateTypePublicDTO } from "../../contracts/models/type-public.model";

@Injectable({
  providedIn: "root"
})
export class TypePublicService extends Api {

  getAll(): Observable<IResponse<TypePublic[]>> {
    return this.http.get<IResponse<TypePublic[]>>(`${this.url}/tipoPublicoMantenimiento`);
  }

  create(createTypePublicDTO: CreateTypePublicDTO): Observable<IResponse<TypePublic>> {
    return this.http.post<IResponse<TypePublic>>(`${this.url}/tipoPublicoMantenimiento`, createTypePublicDTO);
  }

  update(updateTypePublicDTO: UpdateTypePublicDTO): Observable<IResponse<TypePublic>> {
    return this.http.put<IResponse<TypePublic>>(`${this.url}/tipoPublicoMantenimiento/${updateTypePublicDTO.id}`, updateTypePublicDTO);
  }

  delete(id: number): Observable<IResponse<TypePublic>> {
    return this.http.delete<IResponse<TypePublic>>(`${this.url}/tipoPublicoMantenimiento/${id}`);
  }
}