import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Option } from '../../contracts/models/option';
import { Api } from '../../contracts/classes/api.class';

@Injectable({
  providedIn: 'root'
})
export class DynamicSelectService extends Api{

  select() {
    return this.http.get(`${this.url}/atributos`).pipe(
      map((response: any) => response.atributos)
    );
  }

  option() {
    return this.http.get(`${this.url}/tipoAtributos`).pipe(
      map((response: any) => response)
    );
  }

  createOption(data: Option) {
    return this.http.post(`${this.url}/tipoAtributos`, data);
  }

  updateOption(data: Option) {
    return this.http.put(`${this.url}/tipoAtributos/${data.id}`, data);
  }

  deleteOption(id: number) {
    return this.http.delete(`${this.url}/tipoAtributos/${id}`);
  }

  getListBySlug(slug: string) {
    return this.http.get(`${this.url}/tipoAtributos/data/getListBySlug/${slug}`);
  }
}
