import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';

import {PlatformService} from 'src/app/core/services/api/platform.service';
import {AsignarPlataformasFormComponent} from '../form/asignar-plataformas-form.component';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { PlatformMedia } from 'src/app/core/contracts/models/medio-plataformas';

@Component({
    selector: 'app-asignar-plataformas-list',
    templateUrl: './asignar-plataformas-list.component.html',
    styleUrls: ['./asignar-plataformas-list.component.scss']
})
export class AsignarPlataformasListComponent implements OnInit {

    @Input() id: any;
    rows: PlatformMedia[] = [];
    temp: PlatformMedia[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    addPermission = this.auth.isPermit('medio.plataforma.add');
    editPermission = this.auth.isPermit('medio.plataforma.edit');
    deletePermission = this.auth.isPermit('medio.plataforma.delete');

    constructor(
        public dialog: MatDialog,
        private platformService: PlatformService,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.platformService.getListByMedio(this.id).subscribe((response: any) => {
            const {medioPlataformas} = response;
            this.rows = medioPlataformas;
        });
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarPlataformasFormComponent, {
            data: {row, id: Number(this.id)},
            width: '800px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.platformService.deleteMedioPlataformas(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }
}
