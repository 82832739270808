import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

export enum SIDEBAR_OPTIONS {
  SMALL = 'mobile-screen',
  MEDIUM = 'tablet-screen',
  LARGE = 'desktop-screen'
}

@Injectable({ providedIn: 'root' })
export class BreakpointService {

  private breakpoint = inject(BreakpointObserver);

  private sidebar = this.breakpoint.observe([Breakpoints.XSmall, Breakpoints.Small]);

  public sidebar$: Observable<SIDEBAR_OPTIONS> = this.sidebar.pipe(
    map(result => this.matches(result.breakpoints))
  );

  private matches(breakpoints: {[key: string]: boolean}): SIDEBAR_OPTIONS {
    if (breakpoints[Breakpoints.XSmall]) {
      return SIDEBAR_OPTIONS.SMALL;
    }
    else if (breakpoints[Breakpoints.Small]) {
      return SIDEBAR_OPTIONS.MEDIUM
    }
      return SIDEBAR_OPTIONS.LARGE
  }
}

