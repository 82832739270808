import { NgModule } from '@angular/core';
import { ButtonIconComponent } from './button-icon.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
@NgModule({
  declarations: [ButtonIconComponent],
  imports: [MatButtonModule, MatIconModule],
  exports: [ButtonIconComponent],
})
export class ButtonIconModule { }
