import { Injectable, inject } from '@angular/core';
import { Api } from '../../contracts/classes/api.class';
import { ApiAuthResult, LoginCredentials, LoginResult } from '../../contracts/models/auth.model';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { User } from '../../contracts/models/user.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Storage } from '../../utils/storage.class';

@Injectable({providedIn: 'root'})
export class AuthService extends Api {

  private router = inject(Router);
  private storage = inject(Storage);

  private readonly authStorage = environment.localStorageKeys.AUTH;
  private readonly tokenStorage = environment.localStorageKeys.TOKEN;
  private readonly loginUrl: string = `${this.urlv2}/auth/login`;

  private userAuthenticated = new BehaviorSubject<User|undefined>(undefined);
  public user$ = this.userAuthenticated.asObservable();
  
  private token = new BehaviorSubject<string|undefined>(undefined);
  public token$ = this.token.asObservable();

  public isAgent$ = new BehaviorSubject<boolean>(false);
  public isLoggedIn$: Observable<boolean> = this.token.pipe(map(token => Boolean(token) && !this.checkIfTokenHasExpired(token)));

  get isAgent(): boolean {
    return Boolean(this.isAgent$.value);
  }
  get isAdmin(): boolean {
    return ! Boolean(this.isAgent$.value);
  }


  constructor() {
    super();
    this.loadSessionFromLocalStorage();
  }

  get user(): User {
    return this.userAuthenticated.value;
  }

  public updateAuthUser(user: User): void {
    this.storage.setItem(this.authStorage, user);
    this.userAuthenticated.next(user);
  }

  public getTokenValue(): string {
    return this.token.value;
  }

  public updateToken(token: string | null): void {
    this.storage.setItem(this.tokenStorage, token);
    this.token.next(token)
  }


  /**
   * @version API.v2
   * @param credentials 
   * @returns 
   */
  public login(credentials: LoginCredentials): Observable<ApiAuthResult> {
    return this.http.post<ApiAuthResult>( this.loginUrl, credentials )
      .pipe(
        tap(result => this.saveItemsInLocalStorage(result.data)),
        tap(result => this.saveItemsInObservables(result.data)),
        tap(() => this.redirectToDashboard())
      );   
  }

  public logout() : void {
    this.router.navigate(['/authentication/login']);
    localStorage.clear();
    this.token.next(undefined);
    this.userAuthenticated.next(undefined);
  }

  private saveItemsInLocalStorage(data: LoginResult): void {
     this.storage.setItem(this.authStorage, data.user);
     this.storage.setItem(this.tokenStorage, data.token );
  }

  private saveItemsInObservables(data: LoginResult): void {
    this.userAuthenticated.next(data.user);
    this.token.next(data.token);
    const us = data.user.roles.findIndex(rl => rl.id == 1 || rl.id == 2) == -1
    this.isAgent$.next(us)
  }

  private redirectToDashboard(): void {
    this.router.navigateByUrl('/home');
  }

  private loadSessionFromLocalStorage(): void {
    const user = this.storage.getItem<User>(this.authStorage);
    const token = this.storage.getItem<string>(this.tokenStorage);
    user && token && this.saveItemsInObservables({user, token});
  }
  
  /**
   * @param token
   * @returns (true) Si el token ha caducado
   * @returns (false) Si el token sigue vigente
   */
  private checkIfTokenHasExpired(token: string): boolean {
    // Si no hay token, consideramos que está caducado
    if ( !token || token == null ) { return true; }
    // Dividiendo las partes del token
    const tokenParts = token.split('.');
    // El token JWT debe tener tres partes
    if (tokenParts.length !== 3) { return true; }
    // Decodificar el payload en Base64
    const payload = JSON.parse(atob(tokenParts[1]));
    // Si no hay campo 'exp' en el payload, consideramos que está caducado
    if (!payload.exp || typeof payload.exp !== 'number') { return true; }
    // Convertir a milisegundos
    const expirationTimestamp = payload.exp * 1000;
    // Recuperar la fecha actual
    const currentTimestamp = Date.now();

    return Boolean(currentTimestamp >= expirationTimestamp)
  }

  /**
   * Verifica si el usuario autenticado tiene los permisos ingresados
   * Los permisos son reconocidos por su slug
   * @param operator 
   * @param slugs 
   * @returns 
   */
  public hasPermissions(operator: 'OR'|'AND', ...slugs: string[]): boolean {
    const _includes: boolean[] = [];

    const _function = {
      'OR': (data: boolean[]) => data.reduce((a,b) => a||b, false),
      'AND': (data: boolean[]) => data.reduce((a,b) => a&&b, true)
    }

    for(let i = 0; i < slugs.length; i++){
      _includes.push( this.isPermit(slugs[i]) )
    }

    return _function[operator](_includes)
  }

  /**
   * Verifica si el usuario autenticado tiene el permiso ingresado
   * El permiso son reconocidos por su slug
   * @param permission 
   * @returns 
   */
  public isPermit(permission: string): boolean {
    const permissions = this.userAuthenticated.value.permissions.map(p => p.slug);
    return permissions.includes(permission);
  }

  public isPermitMenu() {
    const permissions = this.userAuthenticated.value.permissions.map(p => p.slug);
    return permissions
  }


  goHome() {
    this.router.navigate(['home']);
  }
}
