<div mat-dialog-title class="!text-base !flex items-center">
    {{title}}
    <span class="flex-1"></span>
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<mat-dialog-content>
    <form [formGroup]="myForm" *ngIf="addPermission" (ngSubmit)="onSubmit()" class="mb-4">
        <div class="grid sm:grid-cols-[2fr_1fr_1fr_auto] gap-x-2 gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Plataforma</mat-label>
                <mat-select formControlName="platform" (selectionChange)="onChangeSelect()">
                    <mat-option>Ninguno</mat-option>
                    <mat-option *ngFor="let p of platforms" [value]="p.id">
                        {{p.plataforma_clasificacion.plataforma.descripcion}} >
                        {{p.plataforma_clasificacion.descripcion}}: {{p.valor}}
                    </mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Valor($)</mat-label>
                <input matInput type="number" formControlName="value">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Alcance</mat-label>
                <input matInput type="number" formControlName="alcance">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <adp-button-icon class="relative sm:top-3" (clicked)="onSubmit()" color="primary"
                [disabled]="myForm.invalid" [loading]="!buttonSubmit" [icon]="id ? 'edit' : 'add'"
                label="{{id ? 'Editar' : 'Agregar'}}"></adp-button-icon>
        </div>
    </form>
    <ngx-datatable class="material min" [messages]="messages" [columnMode]="'force'" [loadingIndicator]="isLoading"
        [headerHeight]="45" [footerHeight]="45" [rowHeight]="50" [scrollbarV]="true" [scrollbarH]="true" [rows]="rows">
        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Plataforma">
            <ng-template ngx-datatable-cell-template let-row="row">
                {{row.medio_plataforma.plataforma_clasificacion.plataforma.descripcion}} >
                {{row.medio_plataforma.plataforma_clasificacion.descripcion}}: {{row.medio_plataforma.valor}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Valor" prop="valor">
            <ng-template let-value="value" ngx-datatable-cell-template>
                $ {{value | dolar}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Alcance" prop="alcance">
            <ng-template let-value="value" ngx-datatable-cell-template>
                {{value || 0}}
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Acciones" prop="id" [width]="100" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button mat-stroked-button mat-button-sm color="primary" *ngIf="editPermission" type="button"
                    class="me-2" (click)="edit(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button mat-button-sm *ngIf="deletePermission" type="button" (click)="delete(value)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>
</mat-dialog-content>