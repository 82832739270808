<mat-form-field class="px-4 w-full">
  <mat-select [formControl]="myForm" [placeholder]="placeholder">
    <mat-option>
      <ngx-mat-select-search 
        [formControl]="filteringCtrl" 
        [searching]="searching" 
        [placeholderLabel]="search_placeholder"
        [noEntriesFoundLabel]="empty_label">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of filteredServer | async" [value]="option">
      {{option.label}}
    </mat-option>
  </mat-select>
</mat-form-field>