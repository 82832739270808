import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AsignarContactosFormComponent} from '../form/asignar-contactos-form.component';
import {ProgramaContactosService} from '../../../core/services/api/programa-contactos.service';
import {ProgramaContactos} from '../../../core/contracts/models/contact-program.interface';
import {take} from 'rxjs/operators';
import {EmailEditComponent} from 'app/components/email-edit/email-edit.component';
import {UntypedFormControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { AuthService } from 'src/app/core/services/auth/auth.service';


export type TypeTable = 'edit' | 'show';

@Component({
    selector: 'app-asignar-contactos-list',
    templateUrl: './asignar-contactos-list.component.html',
    styleUrls: ['./asignar-contactos-list.component.scss']
})
export class AsignarContactosListComponent implements OnInit {

    @Input() id: any;
    @Input() public type: TypeTable = 'edit';
    rows: ProgramaContactos[] = [];
    messages = { emptyMessage : 'No hay información para mostrar.' };
    public loading: boolean = false;
    sending = -1;

    searchForm = new UntypedFormControl(null);
    keys = ['contact', 'program', 'cargo', 'plats'];

    list = new BehaviorSubject<any>([]);
    list$ = this.list.asObservable();

    addPermission = this.auth.isPermit('medio.contacto.add');
    editPermission = this.auth.isPermit('medio.contacto.edit');
    deletePermission = this.auth.isPermit('medio.contacto.delete');
    togglePermission = this.auth.isPermit('medio.contacto.activo');

    constructor(
        public dialog: MatDialog,
        private programaContactosService: ProgramaContactosService,
        private auth: AuthService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.loading = true;
        this.getAll();
    }

    getAll() {
        this.programaContactosService.getListByMedio(this.id).subscribe((response: any) => {
            const {programaContactos} = response;
            let rows = programaContactos.map(pr => {
                pr['contact'] = `${pr.contacto.nombres} ${pr.contacto.apellidos}`;
                pr['program'] = pr.programa.nombre;
                pr['cargo'] = pr.cargos.map(cr => cr.descripcion).join(', ');
                pr['plats'] = pr.medioPlataformas.map(pl => {
                    return `${pl.plataforma_clasificacion.plataforma.descripcion} ${pl.valor}`;
                }).join(', ');

                pr['height'] = pr.medioPlataformas.length ? 50 + ((pr.medioPlataformas.length - 1) * 20) : 50;
                return pr;
            });
            this.list.next(rows);
            this.loading = false;
        });
    }

    add() {
        this.openDialog();
    }

    edit(row: any) {
        this.openDialog(row);
    }

    openDialog(row?: any) {
        const dialogRef = this.dialog.open(AsignarContactosFormComponent, {
            data: {row, id: Number(this.id)},
            width: '800px'
        });

        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result) {
                this.getAll();
            }
        });
    }

    delete(id: number) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.programaContactosService.delete(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }

    getRowHeight(row: any) {
        return row.height;
    }

    desactivate(row) {
        const text = row.activo == 1 ? 'Se desactivará el contacto del medio, ¿Desea continuar?' : 'Se activará el contacto del medio, ¿Desea continuar?';
        this.swal.desactiveItem(text).then(val => {
            if (val) {
                this.sending = row.id;
                if (row.activo == 1) {
                    this.openEmailDialog(row);
                    /*this.programaContactosService.desactive(row.id).subscribe(res => {
                      this.swal.showAlert(res['message'],'success')
                      this.sending = -1
                      this.getAll();
                    }, error => {
                      this.swal.showErrorAlert(error.error.message)
                    })*/
                } else {
                    this.programaContactosService.active(row.id).subscribe(res => {
                        this.swal.showAlert(res['message'], 'success');
                        this.sending = -1
                        this.getAll();
                    }, error => {
                        this.swal.showErrorAlert(error.error.message);
                    })
                }
            }
        });
    }

    openEmailDialog(row) { //noFocus
        this.dialog.open(EmailEditComponent, {
            data: row,
            width: '700px'
        }).afterClosed().pipe(take(1)).subscribe(() => {
            this.programaContactosService.desactive(row.id).subscribe(res => {
                this.swal.showAlert(res['message'], 'success');
                this.sending = -1;
                this.getAll();
            }, error => {
                this.swal.showErrorAlert(error.error.message);
            })
        })
    }
}
