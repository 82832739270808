import { ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocomplete, MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { map, Observable, startWith } from "rxjs";
import { Select } from "../../core/contracts/models/form-input.model";
import { OnChanges } from '@angular/core';

@Component({
	selector: "autocomplete-chip-select",
	templateUrl: "./autocomplete-chip-select.component.html",
	styleUrls: ["./autocomplete-chip-select.component.scss"],
})
export class AutocompleteChipSelectComponent implements OnInit, OnChanges {
	readonly separatorKeysCodes: number[] = [ENTER];

	public etiquetas: Select[] = [];
	public filteredLabels: Observable<Select[]>;
	public labelForm = new FormControl();

	@ViewChild("labelInput") public labelInput: ElementRef<HTMLInputElement>;
	@ViewChild("autoLabel") public matAutocomplete: MatAutocomplete;

	@Input() public options: Select[];
	@Input() public initial_select: Select[];
	@Input() public words: boolean;
	@Input() public label: string;
	@Input() public apparience: MatFormFieldAppearance = "fill";
	@Output() public outLabel = new EventEmitter<any>();

	constructor() { }

	public ngOnChanges() {
		if (this.initial_select) {
			this.etiquetas = this.initial_select ? this.initial_select : [];
		}
	}

	public ngOnInit(): void {
		this.filteredLabels = this.labelForm.valueChanges.pipe(
			startWith(null),
			map((inputWord: string | null) => {
				const valueHasLength = inputWord ? inputWord.length > 0 : false;
				const avaliableOptions = this.nonSelectedOptions
					.filter( option =>  
						(typeof inputWord == "string") 
							? this.ifOptionHasInputWord( option, inputWord ) 
							: true 
					);
				return valueHasLength ? avaliableOptions : [];
			})
		);
	}

	private ifOptionHasInputWord( option: Select, word: string ):boolean {
		return this.replaceStrangeCaracters( option.label.toLowerCase() )
		.includes( this.replaceStrangeCaracters(word.toLowerCase().trim()) );
	}

	private get nonSelectedOptions(): Select[] {
		return this.options.filter( (option) => !this.etiquetas.map((etiqueta) => etiqueta.id).includes(option.id) );
	}

	public addChipAdded(event: MatChipInputEvent): void {
		const value = (event.value || "").trim();
		if (value) {
			this.addLabel(value);
		}
	}

	public remove(label): void {
		const index = this.etiquetas.findIndex((et) => et.label == label);
		if (index >= 0) {
			this.etiquetas.splice(index, 1);
		}

		if (this.words) {
			this.outLabel.emit(this.etiquetas.map((el) => el.label));
		} else {
			this.outLabel.emit(this.etiquetas);
		}
	}

	public selected(event: MatAutocompleteSelectedEvent): void {
		const value = event.option.value;
		this.addLabel(value);
	}

	private addLabel(value: string | Select) {
		const isString = typeof value == "string";
		const slug = isString ? value : value.label;
		const ind = this.etiquetas.findIndex(
			(et) =>
				this.replaceStrangeCaracters(et.label.toLowerCase()) ==
				this.replaceStrangeCaracters(slug.toLowerCase().trim())
		);

		if (value || "") {
			if (ind == -1) {
				isString ? null : this.etiquetas.push(value);
			}
		}
		this.labelInput.nativeElement.value = "";
		this.labelForm.setValue(null);
		if (this.words) {
			this.outLabel.emit(this.etiquetas.map((el) => el.label));
		} else {
			this.outLabel.emit(this.etiquetas);
		}
	}

	private replaceStrangeCaracters(strTHML) {
		return (
			strTHML
				.replace(/á/gi, "a")
				.replace(/é/gi, "e")
				.replace(/í/gi, "i")
				.replace(/ó/gi, "o")
				.replace(/ú/gi, "u")
				// .replace(/ñ/gi, "&Ntilde;")
				.replace(/°/gi, " ")
				.replace(/\?/gi, "")
				.replace(/¿/gi, "")
				.replace(/\!/gi, "")
				.replace(/¡/gi, "")
				.replace(/¨/gi, "")
				.replace(/®/gi, "")
				.replace(/@/gi, "")
				.replace(/\$/gi, "")
				.replace(/%/gi, "")
				.replace(/Ü/gi, "u")
		);
	}
}
