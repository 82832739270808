import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from '../services/image.service';
import { environment } from 'environments/environment';
import { User } from '../contracts/models/user.model';
import { Customer } from '../contracts/models/customer';
import { UserLegacy } from '../contracts/models/usuario.model';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  private url: string = environment.app_urls.source;

  constructor(private imageService: ImageService) { }

  async transform(data: UserLegacy | Customer | User, type?: string): Promise<string> {
    try {
      let url = '';
      switch (type) {
        case 'user':
          url = `${this.url}/api/users/data/displayImage/${data.id}`
          break;
        case 'customer':
          url = `${this.url}/api/clientes/data/displayImage/${data.id}?timeStamp=${Date.now()}`
          break;
        case 'resultado':
          url = `${this.url}/api/resultadoPlataformas/data/displayImage/${data}`
          break;
        case 'resultadoImage':
          url = `${this.url}/api/resultadoPlataformas/data/displayImageResultado/${data.id}`
          break;
      }
      const imageBlob = await this.imageService.get(url);
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch (error) {
      if (type === 'user') {
        return 'assets/images/default-avatar.png';
      } else {
        return 'assets/images/noimage.png';
      }
    }
  }

}
