<h2 mat-dialog-title class="title">
    CLIENTE: {{data.plan.campaign.cliente.nombreComercial}}
</h2>

<hr class="line">

<div mat-dialog-content class="!py-0">
    <table class="table-info">
        <td class="details">
            <tr>campaña</tr>
            <tr>Plan de Medio</tr>
            <tr>Contacto</tr>
        </td>
        <td class="details">
            <tr>:</tr>
            <tr>:</tr>
            <tr>:</tr>
        </td>
        <td>
            <tr>{{data.plan.campaign.titulo}}</tr>
            <tr>{{data.plan.nombre | titlecase}}</tr>
            <tr>{{data.row.contact.nombres}} {{data.row.contact.apellidos}}</tr>
        </td>
    </table>

    <table class="table-status">
        <tr>
            <td>Estado Actual:</td>
            <td></td>
            <td>Siguiente Estado:</td>
        </tr>
        <tr>
            <td>
                <div class="statusLabel {{ classStatus }}"><span>{{actualStatus}}</span></div>
            </td>
            <td class="arrow-icon">
                <svg width="25" height="25" fill="#f33" viewBox="0 0 20 15">
                    <path fill-rule="evenodd"
                        d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
                    <path fill-rule="evenodd"
                        d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
                    <path fill-rule="evenodd"
                        d="M11.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L17.293 8 11.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </td>
            <td>
                <div class="statusLabel {{ classNextStatus }}"><span>{{nextStatus}}</span></div>
            </td>
        </tr>
    </table>

    <form [formGroup]="statusPlanForm">
        <mat-form-field style="width: 100%;">
            <mat-label>Resumen</mat-label>
            <textarea autocomplete="off" formControlName="resumen" matInput placeholder="Resumen de nueva Bitácora"
                required></textarea>
        </mat-form-field>
        <mat-form-field style="width: 100%;">
            <mat-label>Tipo de comunicación</mat-label>
            <mat-select formControlName="type">
                <mat-option *ngFor="let option of types" [value]="option">
                    {{option.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button style="margin-right: 5px;" color="primary" type="button" (click)="cancel()">
        Cancelar
    </button>
    <adp-button-icon (clicked)="saveStatus()" color="primary" [disabled]="statusPlanForm.invalid"
        [loading]="buttonLoading" label="Guardar"></adp-button-icon>
</div>