<mat-toolbar style="background-color: white">
    <mat-toolbar-row>
        <span class="fw">Solución de problemas de red</span>
        <span class="example-spacer"></span>
        <button mat-icon-button mat-dialog-close (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row> 
</mat-toolbar>
<mat-dialog-content>
    <div class="flex flex-row">
        <mat-icon style="margin-right: 12px">network_check</mat-icon>
        <h3 class="fw">Estabilidad de red</h3>
    </div>
    <h4>Recomendaciones</h4>
    <div class="flex flex-row">
        <mat-icon style="margin-right: 12px">router</mat-icon>
        <p>Si te conectas a través de wifi, prueba a acercarte un poco más al router.</p>
    </div>
    <div class="flex flex-row">
        <mat-icon style="margin-right: 12px">sports_esports</mat-icon>
        <p>Evita las actividades que realicen un uso intensivo de tu conexión a internet.</p>
    </div>
    <div id="network" class="echarHeight"></div>
</mat-dialog-content>

