<!-- Título del diálogo -->
<div mat-dialog-title class="!text-base">
    {{ title }}
    <span *ngIf="data.stage !== 'isNew'">: {{ editContactName }}</span>
    <mat-progress-bar mode="indeterminate" *ngIf="statusForm === 'loading'"></mat-progress-bar>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="statusForm === 'loading'"></mat-progress-bar>

<!-- Contenido del diálogo -->
<div mat-dialog-content class="py-0">
    <ng-container *ngIf="changeContact$ | async"></ng-container>
    <ng-container *ngIf="changeProgram$ | async"></ng-container>

    <!-- Formulario reactivo asociado al grupo de formulario 'contactBitacoraForm' -->
    <form [formGroup]="contactBitacoraForm">
        <div class="grid sm:grid-cols-1 gap-x-4 gap-y-2 " *ngIf="data.stage === 'isNew'">
            <!-- Campo de busqueda de contactos -->
            <mat-form-field class="w-full">
                <mat-label>Buscar Contacto</mat-label>
                <input matInput type="text" autocomplete="off" formControlName="name" [matAutocomplete]="auto"/>
                <!-- Autocompletado de contactos con un nuevo evento que selecciona el contacto -->
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onContactSelect($event.option.value)">
                    <mat-option *ngFor="let contact of filteredContacts | async" [value]="contact">
                        {{contact.nombres}} {{contact.apellidos}}
                    </mat-option>
                </mat-autocomplete>
                <!-- Error mostrado si el campo no está completo -->
                <mat-error> Completa este campo. </mat-error>
            </mat-form-field>
        </div>

        <!-- Sección para seleccionar programas y plataformas -->
        <div class="grid grid-cols-1 gap-y-2">
            <!-- Selección del programa o medio -->
            <mat-form-field class="w-full">
                <mat-label><b>Medio:</b> programa</mat-label>
                <mat-select disableOptionCentering formControlName="programId" required>
                    <mat-option>
                        <ngx-mat-select-search formControlName="filterMedio" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                        *ngFor="let c of programas| search: contactBitacoraForm.get('filterMedio').value:['medio_programa']"
                        [value]="c.programa_id">
                        <b>{{c.medio_programa}}</b>
                    </mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Plataformas</mat-label>
                <mat-select disableOptionCentering multiple formControlName="platformIds" placeholder="Seleccione">
                    <mat-select-trigger>
                        <!-- Muestra los elementos seleccionados como chips -->
                        <mat-chip-listbox class="multi">
                            <mat-basic-chip class="chipName" *ngFor="let p of selectPlat$|async" [removable]="p.remove"
                                (removed)="remove(p.id)">
                                <mat-icon matChipRemove *ngIf="p.remove">close</mat-icon>
                                <span>{{p.medio_plat_ruta}}</span>
                            </mat-basic-chip>
                        </mat-chip-listbox>
                    </mat-select-trigger>
                    <!-- Campo de búsqueda de plataformas -->
                    <mat-option *ngIf="plataformas.length > 4">
                        <ngx-mat-select-search formControlName="filterP" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option
                        *ngFor="let p of plataformas| search: contactBitacoraForm.get('filterP').value:['plat','descrip','val']"
                        [value]="p.id">
                        {{p.medio_plat_ruta}} 
                    </mat-option>
                </mat-select>
                <!-- Muestra un mensake si no hay plataformas disponibles -->
                <mat-hint align="end" *ngIf="noPlatform">No existen Plataformas vinculadas al Programa / Sección</mat-hint>
            </mat-form-field>
        </div>

        <!-- Sección de voceros -->
        <div>
            <mat-form-field class="w-full">
                <mat-label>Voceros</mat-label>
                <mat-select disableOptionCentering multiple formControlName="spokesmenIds" placeholder="Seleccione">
                    <mat-option *ngFor="let c of spokesmen" [value]="c.id">
                        {{c.nombres}} {{c.apellidos}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <!-- Sección para seleccionar tipo de nota, etapa y tier -->
        <div class="grid gap-y-2 sm:grid-cols-3 sm:gap-x-4" >
            
            <mat-form-field class="w-full" *ngIf="(typesNotes$ | async) as typesNotes">
                <!-- Selección del tipo de nota -->
                <mat-label>Tipo de Nota</mat-label>
                <mat-select formControlName="tipoNota">
                    <mat-option *ngFor="let note of typesNotes.data" [value]="note.id">{{note.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>

            <!-- Selección de la etapa -->
            <mat-form-field class="w-full" *ngIf="(stages$ | async) as stages">
                <mat-label>Etapa</mat-label>
                <mat-select formControlName="tipoEtapa">
                    <mat-option *ngFor="let stage of stages.data" [value]="stage.id">{{stage.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full " *ngIf="(tiers$ | async) as tiers">
                <mat-label>Tier</mat-label>
                <mat-select formControlName="tipoTier">
                    <mat-option *ngFor="let t of tiers.data" [value]="t.id">{{t.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Campos para el control de muestras -->
         <span>Muestras registradas: {{contactBitacoraForm.get('muestrasRegistradas').value}}</span>
        <div class="grid gap-y-2 sm:grid-cols-3 sm:gap-x-4" >
            <!-- Campo para registrar muestras -->
            <mat-form-field class="w-full" appearance="fill" *ngIf="isANumber(contactBitacoraForm.get('muestrasRegistradas').value)">
                <mat-label>Muestras registradas</mat-label>
                <input matInput type="number" autocomplete="off" min="0"
                    formControlName="muestrasRegistradas">
                <mat-error>
                    <span *ngIf="contactBitacoraForm.get('muestrasRegistradas').errors?.['min']">Solo positivos</span>
                </mat-error>
            </mat-form-field>

            <!-- Campo para registrar muestras enviadas -->
            <mat-form-field class="pw-full" appearance="fill" *ngIf="isANumber(contactBitacoraForm.get('muestrasEnviadas').value)">
                <mat-label>Muestras enviadas</mat-label>
                <input matInput type="number" autocomplete="off" min="0"
                    [max]="contactBitacoraForm.get('muestrasRegistradas').value" formControlName="muestrasEnviadas">
                <mat-error>
                    <!-- Mensajes de error por valores fuera de rango -->
                    <span *ngIf="contactBitacoraForm.get('muestrasEnviadas').errors?.['min']">Solo positivos</span>
                    <span *ngIf="contactBitacoraForm.get('muestrasEnviadas').errors?.['maximumExceeded']">
                        No puede ser mayor al número de muestras Registradas</span>
                </mat-error>
            </mat-form-field>

            <!-- Campo para registrar muestras verificadas -->
            <mat-form-field class="w-full " appearance="fill" *ngIf="isANumber(contactBitacoraForm.get('muestrasVerificadas').value)">
                <mat-label>Muestras verificadas</mat-label>
                <input matInput type="number" autocomplete="off" min="0"
                    [max]="contactBitacoraForm.get('muestrasEnviadas').value" formControlName="muestrasVerificadas">
                <mat-error>
                    <span *ngIf="contactBitacoraForm.get('muestrasVerificadas').errors?.['min']">Solo positivos</span>
                    <span *ngIf="contactBitacoraForm.get('muestrasVerificadas').errors?.['maximumExceeded']">
                        No puede ser mayor al número de muestras Enviadas</span>
                </mat-error>
            </mat-form-field>
        </div>

        <!-- Campo para la bitácora, que se muestra si es una nueva entrada o instancia -->
        <div *ngIf="data.stage === 'isNew' || data.stage === 'instanced'" class="flex-row flex-wrap justify-start items-center content-center">
            <mat-form-field class="w-full">
                <mat-label>Bitácora</mat-label>
                <textarea matInput autocomplete="off" formControlName="observation" 
                    placeholder="Primera Bitácora"></textarea>
            </mat-form-field>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" (click)="closeDialog()" type="button">Cancelar</button>
    <adp-button-icon (clicked)="data.stage === 'isNew' ? addContact() : editContact()" color="primary" [disabled]="contactBitacoraForm.invalid" [loading]="formLoading"
        label="Guardar"></adp-button-icon>
</div>