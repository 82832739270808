import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Api } from '../../contracts/classes/api.class';
import { Campaign } from '../../contracts/models/campaign.interface';

@Injectable({providedIn: 'root'})
export class CampaignsService extends Api {

  getAll() {
    return this.http.get(`${this.url}/campaigns`);
  }

  getListSelect() {
    return this.http.get(`${this.url}/campaigns/select`);
  }

  getListPagination(page) {
    return this.http.get(`${this.url}/campaign/list${page}`);
  }

  getId(id: number) {
    return this.http.get(`${this.url}/campaigns/${id}`).pipe(map((response: any) => response.campaign));
  }

  create(data: Campaign) {
    return this.http.post(`${this.url}/campaigns`, data);
  }

  edit(data: Campaign) {
    return this.http.put(`${this.url}/campaigns/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/campaigns/${id}`);
  }

  getByCliente(idCliente) {
    return this.http.get(`${this.url}/campaigns/data/getListByCliente/${idCliente}`);
  }

  getByLogged() {
    return this.http.get(`${this.url}/campaigns/data/getListByLogged`);
  }

  getAllCampaignVoceros(idCampaign: number) {
    return this.http.get(`${this.url}/campaignVoceros/data/getListByCampaign/${idCampaign}`);
  }

  createCampaignVoceros(data: any) {
    return this.http.post(`${this.url}/campaignVoceros`, data);
  }

  editCampaignVoceros(data: any) {
    return this.http.put(`${this.url}/campaignVoceros/${data.id}`, data);
  }

  deleteCampaignVoceros(id: number) {
    return this.http.delete(`${this.url}/campaignVoceros/${id}`);
  }

  getReports(data) {
    return this.http.post(`${this.url}/resultadoPlataformas/data/getResultadosForReporte`, data);
  }

  getTypeReports(data) {
    return this.http.post(`${this.url}/resultadoPlataformas/data/generateReporte`, data);
  }

  getLabels() {
    return this.http.get(`${this.url}/etiquetas`);
  }

  getTotalReports(data) {
    return this.http.post(`${this.url}/campaigns/data/getListForReporte`, data);
  }

  analisys(data) {
    return this.http.post(`${this.url}/detallePlanMedios/data/analisisEstrategico`, data);
  }

  saveValor(data) {
    return this.http.post(`${this.url}/campaigns/data/valorar`, data);
  }

  getAllCampaignAgente(idCampaign: number) {
    return this.http.get(`${this.url}/campaignResponsables/data/getListByCampaign/${idCampaign}`);
  }

  createCampaignAgente(data: any) {
    return this.http.post(`${this.url}/campaignResponsables`, data);
  }

  deleteCampaignAgente(id: number) {
    return this.http.delete(`${this.url}/campaignResponsables/${id}`);
  }

  getCampaignByDate(data: any) {
    return this.http.post(`${this.url}/campaigns/data/getListByDates`, data);
  }
}
