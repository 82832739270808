import { Component, inject, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignsService } from 'src/app/core/services/api/campaigns.service';
import { MediaPlanService } from 'src/app/core/services/api/media-plan.service';
import { PeopleService } from 'src/app/core/services/api/people.service';
import { ProgramaContactosService } from 'src/app/core/services/api/programa-contactos.service';
import { Observable, of } from 'rxjs';
import { map, startWith, switchMap, debounceTime, distinctUntilChanged, catchError } from 'rxjs/operators';
import { typesNotes, stages, tiers } from 'app/shared/general/datageneral';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { ProgramService } from 'src/app/core/services/api/program.service';
import { ContactBitacora } from 'app/core/contracts/models/bitacora-form';
import { ProgramSelect } from 'app/core/contracts/models/program';
import { DpmDTO, DpmUpdateDTO } from 'src/app/core/contracts/models/detailed-media-plan.model';
import { ApiContact } from 'src/app/core/contracts/models/contacto.model';
import { TypeNoteService } from 'src/app/core/services/api/type-note.service';
import { StagesService } from 'src/app/core/services/api/stages.service';
import { TiersService } from 'src/app/core/services/api/tiers.service';

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html'
})
export class ContactFormComponent implements OnInit {

    // Definición del formulario reactivo con varios controles, cada uno con sus validaciones iniciales
    contactBitacoraForm = this.formBuilder.group({
        contactId: new FormControl<number | null>({ value: 0, disabled: true }, [Validators.required]),
        programId: new FormControl<number | null>({ value: 0, disabled: true }, [Validators.required]),
        platformIds: new FormControl<Array<any> | null>({ value: [], disabled: true }, [Validators.required]),
        spokesmenIds: new FormControl<Array<any> | null>([], [Validators.required]),
        tipoNota: new FormControl<number>(1,[Validators.required]),
        tipoEtapa: new FormControl<number>(1,[Validators.required]),
        tipoTier: new FormControl<number>(1,[Validators.required]),
        muestrasRegistradas: new FormControl<number>(0, [Validators.min(0)]),
        muestrasEnviadas:new FormControl<number>(0,[Validators.min(0), this.exceededValidator('muestrasRegistradas')]),
        muestrasVerificadas:new FormControl<number>(0,[Validators.min(0), this.exceededValidator('muestrasEnviadas')]),
        observation:new FormControl<string>('',[Validators.required]) ,
        name:new FormControl<string>(''),
        filterMedio: new FormControl<string>(''),
        filterP: new FormControl<string>(''),
    });

    // Variables para manejar el estado del formulario y otras propiedades de la interfaz
    statusForm: 'init' | 'loading' | 'error' | 'complete' = 'init';
    isLoading: boolean = true;
    formLoading: boolean = false;
    title: string = '';

    typesNotesService = inject(TypeNoteService);
    stagesService = inject(StagesService);
    tiersService = inject(TiersService);
    
    typesNotes$ = this.typesNotesService.getAll();
    stages$ = this.stagesService.getAll();
    tiers$ = this.tiersService.getAll();

    contacts: ApiContact[] = []; //Lista de contactos
    programas: ProgramSelect[] = []; //Lista de programas
    plataformas: any[] = []; //Lista de plataformas
    spokesmen: Array<any>; //Lista de voceros

    loader = false; //Indicador de carga
    idContact: number | null = null; // ID del contacto seleccionado.
    countPlat = 0; //Contador para plataformas.
    countMed = 0; //Contador para medio.
    linked = false; // Bandera para saber si esta vinculado.
    publish = false; // Bandera para saber si esta publicado.

    idsPublic: number[] = []; // IDs de los publicados

    // Observables para manejar cambios en el formulario
    changeContact$: Observable<any>;
    changeProgram$: Observable<any>;
    selectPlat$: Observable<any>;

    noPlatform = false; //Indica si no hay plataformas
    editContactName: string = ''; //Nombre del contacto en edición

    filteredContacts: Observable<ApiContact[]>; // Observable para los contactos filtrados

    constructor(
        private dialogRef: MatDialogRef<ContactFormComponent>,
        private formBuilder: FormBuilder,
        private programaContactosService: ProgramaContactosService,
        private peopleService: PeopleService,
        private mediaPlanService: MediaPlanService,
        private campaignsService: CampaignsService,
        private programService: ProgramService,
        private swal: Swal2,
        @Inject(MAT_DIALOG_DATA) public data: ContactBitacora
    ) {
        // Se establece el título del formulario según si es un nuevo contacto o se está editando
        if (this.data.stage === 'isNew') {
            this.title = 'Agregar Contacto'
        } else {
            this.title = 'Editar Contacto';
            // Si es edición, se rellenan los campos del formulario con los datos existentes
            this.contactBitacoraForm.patchValue(this.data.form);
            this.editContactName = `${this.data.row.contact.nombres} ${this.data.row.contact.apellidos}`
        }
    }

    ngOnInit(): void {
        // Se cargan los voceros relacionados con la campaña al inicializar el componente
        this.campaignsService.getAllCampaignVoceros(this.data.idCampaign).subscribe((response: any) => {
            const { campaignVoceros } = response;
            this.spokesmen = campaignVoceros.map(el => el.vocero);
        });
        // Observa cambios en el campo contacId y ejecuta acciones correspondientes.
        if (this.data.stage === 'isNew') {
            this.changeContact$ = this.contactBitacoraForm.controls.contactId.valueChanges.pipe(
                map(contact => {
                    if (contact) {
                        // Resetea y deshabilita programId cuando contacId cambia.
                        this.contactBitacoraForm.controls.programId.reset();
                        this.idContact = contact;
                        this.getAllProgramaContact(contact); // Carga los programas asociados al contacto.
                    } else {
                        this.contactBitacoraForm.controls.programId.disable({ emitEvent: false });
                    }
                })
            )
        } else {
            // Si es edición, carga los programas asociados al contacto y habilita contacID.
            this.getAllProgramaContact(this.data.form.contactId)
            this.contactBitacoraForm.controls.contactId.enable({ emitEvent: false });
        }
        // Observa cambios en el campo programId para cargar las plataformas correspondientes.
        this.changeProgram$ = this.contactBitacoraForm.controls.programId.valueChanges.pipe(
            map(medio => {
                this.contactBitacoraForm.controls.platformIds.reset();
                if (medio) {
                    this.getAllProgramaPlataformas(medio);
                } else {
                    this.contactBitacoraForm.controls.platformIds.disable({ emitEvent: false });
                }
            })
        )
        //Observa cambios en platformIds para filtrar las plataformas seleccionadas.
        this.selectPlat$ = this.contactBitacoraForm.controls.platformIds.valueChanges.pipe(
            map(plats =>
                (plats)
                    ? this.plataformas.filter(pl => plats.includes(pl.id))
                    : []
            )
        )

        // this.statusForm = 'init'

        // Modificación: Filtra los contactos solo cuando el usuario empieza a escribir
        this.filteredContacts = this.contactBitacoraForm.controls.name.valueChanges.pipe(
            startWith(''),
            debounceTime(300),
            distinctUntilChanged(),
            switchMap(value => {
                if (value && value.length > 0) {
                    const filterContacts = this.filterContacts(value);
                    // console.log('filterContacts:', filterContacts);  // Para depuración
                    return filterContacts;
                } else {
                    return of([]); // Retorna un observable vacío si no hay valor
                }
            }),
            catchError(error => {
                console.error('Error fetching contacts:', error);
                return of([]);
            })
        );
    }

    // Filtra contactos según el valor introducido en el campo de búsqueda.
    private filterContacts(value: string): Observable<ApiContact[]> {
        this.statusForm = 'loading';
        // debugger
        return this.peopleService.searchContactosSelect(value).pipe(
            map((response: any) => {
                this.statusForm = 'init';
                if (response.ready && response.contactos) {
                    // Asegúrate de que contactos sea un array
                    this.contacts = Array.isArray(response.contactos) ? response.contactos : Object.values(response.contactos);
                    return this.contacts;
                } else {
                    console.warn('No contacts found or unexpected response format');
                    this.swal.showToast(response.message || 'No se encontraron contactos', 'warning');
                    return [];
                }
            }),
            catchError(error => {
                console.error('Error in filterContacts:', error);
                this.statusForm = 'error';
                this.swal.showToast('Error al buscar contactos', 'error');
                return of([]);
            })
        );
    }
    // Obtiene los programas asociados a un contacto seleccionado.
    getAllProgramaContact(id: number) {
        this.statusForm = 'loading'
        // debugger
        this.programaContactosService.getListByContactoSelect(id).subscribe((response: any) => {
            const { select } = response;
            this.programas = select;

            if (!this.linked && this.data.stage !== 'published') {
                this.contactBitacoraForm.controls.programId.enable({ emitEvent: false })
            }
            if (this.data.row) {
                this.idContact = this.data.form.contactId
                this.contactBitacoraForm.controls.programId.setValue(this.data.form.programId)
            }
            this.statusForm = 'init'
        });
    }

    // Obtiene las plataformas asociadas al programa seleccionado.
    getAllProgramaPlataformas(programa_id: number) {
        this.statusForm = 'loading'
        // debugger
        if (this.noPlatform) { this.noPlatform = false }

        const program = this.programas.find(pr => pr.programa_id == programa_id);
        if (program) {
            this.statusForm = 'loading'
// debugger
            this.programService.getAllProgramaPlataformasSelect(programa_id, this.idContact).subscribe((res: any) => {
                const { select } = res;
                this.plataformas = select
                if (this.plataformas.length) {
                    if (this.contactBitacoraForm.controls.platformIds.disabled) {
                        this.contactBitacoraForm.controls.platformIds.enable({ emitEvent: false });
                    }
                } else {
                    this.noPlatform = true;
                    if (this.contactBitacoraForm.controls.platformIds.enabled) {
                        this.contactBitacoraForm.controls.platformIds.disable({ emitEvent: false });
                    }
                }

                setTimeout(() => {
                    if (this.data.stage !== 'isNew' && this.countPlat == 0) {
                        this.contactBitacoraForm.controls.platformIds.setValue(this.data.form.platformIds);
                        if (this.linked) {
                            this.contactBitacoraForm.controls.platformIds.disable({ emitEvent: false });
                        }
                        this.statusForm = 'init'
                        this.countPlat++;
                    } else {
                        this.statusForm = 'init'
                    }
                });
            });
        } else {
            this.statusForm = 'init'
        }
    }

    // Verifica si el formulario es válido
    isFormValid(): boolean {
        return this.contactBitacoraForm.invalid;
    }

    // Añade un nuevo contacto
    addContact() {
        this.contactBitacoraForm.markAsPristine();
        this.contactBitacoraForm.markAsUntouched();
        this.formLoading = true;

        let newContact: DpmDTO = {
            idPlanMedio: this.data.id,
            idsMedioPlataforma: this.contactBitacoraForm.controls.platformIds.value,
            muestrasRegistradas: this.contactBitacoraForm.controls.muestrasRegistradas.value,
            muestrasEnviadas: this.contactBitacoraForm.controls.muestrasEnviadas.value,
            muestrasVerificadas: this.contactBitacoraForm.controls.muestrasVerificadas.value,
            tipoNota: this.contactBitacoraForm.controls.tipoNota.value,
            tipoEtapa: this.contactBitacoraForm.controls.tipoEtapa.value,
            tipoTier: this.contactBitacoraForm.controls.tipoTier.value,
            voceros: this.contactBitacoraForm.controls.spokesmenIds.value,
            observacion: this.contactBitacoraForm.controls.observation.value
        }

        for (let prog of this.programas) {
            if (prog.programa_id == this.contactBitacoraForm.controls.programId.value) {
                newContact['idProgramaContacto'] = prog.programaContacto_id
            }
        }

        if (this.data.newMediaPlan) {
            this.swal.showToast('Contacto agregado exitosamente!!', 'success')
            this.dialogRef.close({
                stage: this.data.stage,
                contact: this.contacts.find(c => c.id == this.idContact),
                form: newContact,
                programa: this.programas.find(pr => pr.programaContacto_id == newContact['idProgramaContacto']),
                plataformas: this.plataformas.filter(pl => newContact.idsMedioPlataforma.find(p => pl.id == p))
            })
        }
        else this.createContactDetail(newContact)
    }

    // Edita un contacto existente
    editContact() {
        const medioPrograma = this.programas.find(pr => pr.programa_id == this.contactBitacoraForm.controls.programId.value)
        let contact: DpmUpdateDTO = {
            idPlanMedio: this.data.id,
            idsMedioPlataforma: this.contactBitacoraForm.controls.platformIds.value,
            idProgramaContacto: medioPrograma.programaContacto_id,
            muestrasRegistradas: this.contactBitacoraForm.controls.muestrasRegistradas.value,
            muestrasEnviadas: this.contactBitacoraForm.controls.muestrasEnviadas.value,
            muestrasVerificadas: this.contactBitacoraForm.controls.muestrasVerificadas.value,
            tipoNota: this.contactBitacoraForm.controls.tipoNota.value,
            tipoEtapa: this.contactBitacoraForm.controls.tipoEtapa.value,
            tipoTier: this.contactBitacoraForm.controls.tipoTier.value,
            voceros: this.contactBitacoraForm.controls.spokesmenIds.value,
            observacion: this.contactBitacoraForm.controls.observation.value
        };

        if (this.data.newMediaPlan) {
            this.swal.showToast('Contacto editado exitosamente!!', 'success')
            this.dialogRef.close({
                id: this.data.id,
                stage: this.data.stage,
                contact: this.contacts.find(c => c.id == this.idContact),
                form: contact,
                programa: this.programas.find(pr => pr.programaContacto_id == contact['idProgramaContacto']),
                plataformas: this.plataformas.filter(pl => contact.idsMedioPlataforma.find(p => pl.id == p))
            })
        }
        else this.editContactDetail(contact, this.data.id)
    }

    // Cierra el diálogo
    closeDialog() {
        this.dialogRef.close({ action: true, stage: 'closeDialog' });
    }

    // Crea un nuevo detalle de contacto
    createContactDetail(newContact: DpmDTO) {
        this.mediaPlanService.createDetail(newContact).subscribe({
            next: (response: any) => {
                this.swal.showToast(response.message, 'success')
                this.dialogRef.close({ action: true })
            },
            error: (error) => {
                this.swal.showErrorAlert(error.error.message, `Error ${error.status}`)
                this.formLoading = false;
            }
        });
    }

    // Edita un detalle de contacto existente
    editContactDetail(contact: DpmUpdateDTO, id: number) {
        this.mediaPlanService.editDetail(contact, id).subscribe({
            next: (response: any) => {
                this.swal.showToast(response.message, 'success')
                this.dialogRef.close({ action: true, contact })
            },
            error: (error) => {
                this.swal.showErrorAlert(error.error.message, `Error ${error.status}`)
                this.formLoading = false;
            }
        });
    }

    // Remueve una plataforma seleccionada
    remove(id: number): void {
        let preSelects = [...this.contactBitacoraForm.controls.platformIds.value]

        const index = preSelects.indexOf(id);
        if (index != -1) {
            const filteredOptions = [...preSelects].filter(op => op != id);
            this.contactBitacoraForm.controls.platformIds.patchValue(filteredOptions);
        }
    }

    // Validador personalizado para verificar que un valor no exceda otro
    exceededValidator(form: string) {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value
            if (control.parent) {
                if (value > control.parent.get(form).value) {
                    return { 'maximumExceeded': true }
                } else {
                    return null
                }
            } else {
                return null
            }
        }
    }

    // Maneja la selección de un contacto
    onContactSelect(contact: ApiContact) {
        console.log('Contact selected:', contact);  // Para depuración
        this.contactBitacoraForm.patchValue({
            contactId: contact.id,
            name: `${contact.nombres} ${contact.apellidos}`
        });
        this.getAllProgramaContact(contact.id);
    }

    isANumber(value: any): boolean {
        return typeof value === 'number';
    }
}