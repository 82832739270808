import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../contracts/types/core.type';

@Pipe({
  name: 'dolar'
})
export class DolarPipe implements PipeTransform {

  transform(value: number, currency: Currency|undefined = undefined): unknown {

    let number = value.toLocaleString("fr", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).replace(',', '.').replace(/\s/g, '  ')
    if(!Boolean(currency)) {
      return number
    } else {
      const symbol = currency == 'sol' ? 'S/.' : 'USD.'
      return `${symbol} ${number}`
    }
  }

}
