import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Api } from '../../contracts/classes/api.class';
import { IResponse } from '../../contracts/models/response.model';
import { CreateTypeNoteDTO, TypeNote, UpdateTypeNoteDTO } from '../../contracts/models/type-note.model';

@Injectable({
  providedIn: 'root'
})
export class TypeNotesService extends Api {

  typeNotesBs: BehaviorSubject<TypeNote[]> = new BehaviorSubject<TypeNote[]>([]);

  getAll(): Observable<IResponse<TypeNote[]>> {
    return this.http.get<IResponse<TypeNote[]>>(`${this.url}/tipoNotaMantenimiento`);
  }

  create(createTypeNoteDTO: CreateTypeNoteDTO): Observable<IResponse<TypeNote>> {
    return this.http.post<IResponse<TypeNote>>(`${this.url}/tipoNotaMantenimiento`, createTypeNoteDTO);
  }

  update(updateTypeNoteDTO: UpdateTypeNoteDTO): Observable<IResponse<TypeNote>> {
    return this.http.put<IResponse<TypeNote>>(`${this.url}/tipoNotaMantenimiento/${updateTypeNoteDTO.id}`, updateTypeNoteDTO);
  }

  delete(id: number): Observable<IResponse<TypeNote>> {
    return this.http.delete<IResponse<TypeNote>>(`${this.url}/tipoNotaMantenimiento/${id}`);
  }

  getTypeNotes(): TypeNote[] {
    return this.typeNotesBs.getValue();
  }

  updateTypeNotes(typeNotes: TypeNote[]) {
    this.typeNotesBs.next(typeNotes);
  }

}