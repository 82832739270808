import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import {UserService} from 'src/app/core/services/api/user.service';
import {MediaPlanService} from 'src/app/core/services/api/media-plan.service';

@Component({
    selector: 'app-contact-transfer',
    templateUrl: './contact-transfer.component.html'
})
export class ContactTransferComponent implements OnInit {

    users: any = [];
    myForm = this.formBuilder.group({
        agente: new FormControl<string|null>(null,Validators.required),
        filter: new FormControl<string|null>(null)
    });

    loading = false;

    constructor(
        private formBuilder: FormBuilder,
        public userService: UserService,
        public dialogRef: MatDialogRef<ContactTransferComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: any, mass: boolean, selects?: any[] },
        private mediaPlanService: MediaPlanService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.userService.legacyGetAll().subscribe((response: any) => {
            this.users = response.users;
        });
    }

    save() {
        if (this.data.mass) {
            this.addMass();
        } else {
            this.add();
        }
    }

    add() {
        const agent = this.myForm.controls.agente.value
        const send = {
            idDetallePlanMedio: this.data.row.id,
            user_id: agent
        }
        this.loading = true;
        this.mediaPlanService.transferContact(send).subscribe((response: any) => {
            this.loading = false;
            this.swal.showToast(response.message, 'success');
            this.myForm.controls.agente.reset();
            this.dialogRef.close({
                action: true
            })
        }, error => {
            this.swal.showToast(error.error.message, 'error');
            this.myForm.controls.agente.reset();
            this.loading = false;
        });
    }

    addMass() {
        const agent = this.myForm.controls.agente.value;
        const send = {
            idsDetallePlanMedio: this.data.selects.map(s => s.id),
            user_id: agent
        }
        this.loading = true;
        this.mediaPlanService.transferContactMass(send).subscribe((response: any) => {
            this.loading = false;
            this.swal.showToast(response.message, 'success');
            this.myForm.controls.agente.reset();
            this.dialogRef.close({
                action: true,
                user: agent
            })
        }, error => {
            this.swal.showToast(error.error.message, 'error');
            this.myForm.controls.agente.reset();
            this.loading = false;
        });
    }

}
