import {Component, OnInit, Inject} from '@angular/core';
import {Option} from '../../../core/contracts/models/option';
import {Network} from '../../../core/contracts/models/network';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DynamicSelectService} from 'src/app/core/services/api/dynamic-select.service';

@Component({
    selector: 'app-social-networks-form',
    templateUrl: './social-networks-form.component.html',
    styleUrls: ['./social-networks-form.component.scss']
})
export class SocialNetworksFormComponent implements OnInit {


    socialNetwork: Network = {
        idTipoRed: null,
        tipo_red: null,
        red: null
    }
    socialNetworks: Option[] = [];

    title = 'Agregar red social';

    constructor(
        public dialogRef: MatDialogRef<SocialNetworksFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Network,
        private dynamicSelectService: DynamicSelectService
    ) {
        if (data) {
            this.title = 'Editar red social';
            this.socialNetwork.idTipoRed = data.tipo_red.id;
            this.socialNetwork.tipo_red = data.tipo_red;
            this.socialNetwork.red = data.red;
        }
    }

    ngOnInit(): void {
        this.dynamicSelectService.getListBySlug('social-media').subscribe((response: any) => {
            this.socialNetworks = response.tipoAtributos;
        });
    }

    onSubmit() {
        this.socialNetwork.tipo_red = this.socialNetworks.find(s => s.id === this.socialNetwork.idTipoRed);
        this.socialNetwork.red = this.socialNetwork.red.replace(/ /g, '');
        this.dialogRef.close({...this.socialNetwork});
    }

    cancel() {
        this.dialogRef.close(this.data);
    }

}
