<div class="px-4 my-4">
    <!-- Encabezado con título y botón de agregar -->
    <div class="flex flex-row flex-wrap justify-between items-center mb-4">
        <h3 class="m-0">Programa</h3>
        <button type="button" mat-raised-button color="primary" *ngIf="addPermission" (click)="openDialog()">
            Agregar programa
        </button>
    </div>

    <!-- Campo de búsqueda -->
    <mat-form-field class="w-full mb-4">
        <input matInput placeholder="Buscar..." autocomplete="off" [formControl]="searchForm">
    </mat-form-field>

    <!-- Tabla de datos -->
    <ngx-datatable 
        class="material min-h-[400px]"
        [messages]="messages" 
        [columnMode]="'force'" 
        [headerHeight]="45" 
        [footerHeight]="45"
        [rowHeight]="100"
        [scrollbarV]="true" 
        [scrollbarH]="true" 
        [rows]="rows| search: searchForm.value:keys">

        <!-- Columna de numeración -->
        <ngx-datatable-column name="#" [width]="50" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
                <span class="pl-2">{{ rowIndex + 1 }}</span>
            </ng-template>
        </ngx-datatable-column>

        <!-- Columna de Nombre -->
        <ngx-datatable-column name="Nombre" prop="nombre" [width]="180">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span class="pl-2">{{ value }}</span>
            </ng-template>
        </ngx-datatable-column>

        <!-- Columna de Descripción -->
        <ngx-datatable-column name="Descripción" prop="descripcion" [width]="200">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <span class="pl-2">{{ value }}</span>
            </ng-template>
        </ngx-datatable-column>

        <!-- Columna de Plataformas -->
        <ngx-datatable-column name="Plataformas" prop="programa_plataformas" [width]="200">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <div class="flex flex-row flex-wrap justify-start items-center pl-2 gap-2">
                    <div *ngFor="let v of value" class="platform-icon-container">
                        <ng-container [ngSwitch]="getIconName(v.medio_plat, v.medio_plat_clas)">
                            <mat-icon *ngSwitchCase="'language'" class="platform-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor">language</mat-icon>
                            <mat-icon *ngSwitchCase="'tv'" class="platform-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor">tv</mat-icon>
                            <mat-icon *ngSwitchCase="'radio'" class="platform-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor">radio</mat-icon>
                            <mat-icon *ngSwitchCase="'menu_book'" class="platform-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor">menu_book</mat-icon>
                            <i *ngSwitchCase="'fab fa-facebook-f'" class="fab fa-facebook-f platform-icon social-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor"></i>
                            <i *ngSwitchCase="'fab fa-youtube'" class="fab fa-youtube platform-icon social-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor"></i>
                            <i *ngSwitchCase="'fab fa-twitter'" class="fab fa-twitter platform-icon social-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor"></i>
                            <i *ngSwitchCase="'fab fa-instagram'" class="fab fa-instagram platform-icon social-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor"></i>
                            <i *ngSwitchCase="'fab fa-spotify'" class="fab fa-spotify platform-icon social-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor"></i>
                            <i *ngSwitchCase="'fab fa-linkedin-in'" class="fab fa-linkedin-in platform-icon social-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor"></i>
                            <i *ngSwitchCase="'fab fa-tiktok'" class="fab fa-tiktok platform-icon social-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor"></i>
                            <mat-icon *ngSwitchDefault class="platform-icon" [matTooltip]="v.medio_plat + ' ' + v.medio_plat_clas + ': ' + v.medio_plat_valor">{{getIconName(v.medio_plat, v.medio_plat_clas)}}</mat-icon>
                        </ng-container>
                    </div>
                </div>
            </ng-template>
        </ngx-datatable-column>

        <!-- Columna de Acciones -->
        <ngx-datatable-column name="Acciones" [width]="150" [sortable]="false">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <div class="flex justify-start items-center pl-2">
                    <button mat-stroked-button mat-button-sm color="primary" type="button" class="mr-2" (click)="edit(row)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-stroked-button mat-button-sm type="button" *ngIf="editPermission" class="mr-2" (click)="link(row)">
                        <mat-icon>link</mat-icon>
                    </button>
                    <button mat-stroked-button mat-button-sm type="button" *ngIf="deletePermission" (click)="delete(row.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>