import {Component, Inject, OnInit, ElementRef, ViewChild, inject} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {PeopleService} from 'src/app/core/services/api/people.service';
import {MediaPlanService} from 'src/app/core/services/api/media-plan.service';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import {CustomerService} from 'src/app/core/services/api/customer.service';
import { formatDate } from '@angular/common';
import { Storage } from 'src/app/core/utils/storage.class';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-send-email-dialog',
    templateUrl: './send-email-dialog.component.html',
    styleUrls: ['./send-email-dialog.component.scss']
})
export class SendEmailDialogComponent implements OnInit {

    private storage = inject(Storage);

    emailForm: UntypedFormGroup = this.formBuilder.group({
        to: [null],
        subject: [null],
        message: [null]
    });

    visible = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    emails: any[] = [];
    contacts: any[] = [];
    usersWithoutMail: any[] = [];

    @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
    @ViewChild('auto') matAutocomplete: MatAutocomplete;

    selectedFile: Array<any> = [];
    config = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            [{'header': 1}, {'header': 2}],               // custom button values
            [{'list': 'ordered'}, {'list': 'bullet'}],
            [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
            [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': ['center', 'right', 'justify']}]
        ]
    };

    editorStyle = {
        height: '250px'
    };

    default: string;

    buttonSubmit = false;

    filteredFruits: Observable<string[]>;
    fruits: string[] = [];
    allFruits: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<SendEmailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: UntypedFormBuilder,
        private peopleService: PeopleService,
        private mediaplanService: MediaPlanService,
        private customerService: CustomerService,
        private swal: Swal2
    ) {
        let user = this.storage.getItem(environment.localStorageKeys.AUTH);

        const fechaInicio = formatDate(this.data.report.fechaInicio, 'dd-MM-yyyy',"es-PE")
        const fechaFin = formatDate(this.data.report.fechaFin, 'dd-MM-yyyy',"es-PE")
        this.emailForm.get('message').setValue( this.getDefaultMessage( user.name, fechaInicio, fechaFin ) )
    }

    ngOnInit(): void {
        
        if (this.data.type == 'nota') {
            this.default = `<p>Estimados,</p><p>&nbsp;</p><p>Por encargo de ${this.data.customer.nombreComercial}, le enviamos:</p><p>&nbsp;</p><p>&nbsp;</p><p>Saludos cordiales,</p><p>${this.data.user}</p>`
            this.emailForm.get('message').setValue(this.default)
            this.peopleService.getContactos().subscribe((response: any) => {
                const {contactos} = response;
                const contacs = contactos.filter(ct => this.data.data.includes(ct.id)).map(el => {
                    return el.emails.map(em => {
                        return {
                            displayName: el.nombres + ' ' + el.apellidos,
                            email: em.email,
                            type: em.tipo_email.name,
                            isContact: true
                        }
                    })
                }).reduce((a, b) => a.concat(b), [])

                this.usersWithoutMail = contactos.filter(ct => this.data.data.includes(ct.id)).filter(ct => ct.emails.length == 0);

                this.contacts = [...contacs];
                // this.emails = [...contacs]
            });
        } else {
            
            this.customerService.getAllClienteEncargados(this.data.customer.id).subscribe(response => {
                const contacs = response['clienteEncargados'].map(el => {
                    return el.encargado.emails.map(em => {
                        return {
                            displayName: el.encargado.nombres + ' ' + el.encargado.apellidos,
                            email: em.email,
                            isContact: true
                        }
                    })
                }).reduce((a, b) => a.concat(b), [])
                this.contacts = contacs;
            })
        }
    }

    private getDefaultMessage( user: string, init_date: string, end_date: string): string {
        return `
        <p>Saludos,</p> 
        <p>Espero que te encuentres bien. Te comparto el reporte que resume las actividades y los logros entre las fechas ${init_date} al ${end_date}</p>
        <h4>Dentro del reporte se podrán visualizar:</h4>
        <ul>
            <li>El alcance y valorización total</li>
            <li>Las plataformas y la cantidad de publicaciones realizadas en cada una.</li>
        </ul>
        <p>Quedo atento a tus comentarios</p>
        <br>
        <p>Atentamente,</p>
        <p>${user}</p>`
    }

    onFileSelected(event): void {
        if (event.target.files && event.target.files[0]) {
            this.selectedFile.push(event.target.files[0]);
        }
    }

    removeFile(index): void {
        if (index >= 0) {
            this.selectedFile.splice(index, 1);
        }
    }

    disabledButton() {
        return this.buttonSubmit || !this.emails.length || !this.emailForm.get('subject').value;
    }

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;
        this.addEmail(value)
        if (input) {
            input.value = '';
        }
    }

    remove(email: string): void {
        const index = this.emails.findIndex(et => et.email == email);
        if (index >= 0) {
            this.emails.splice(index, 1);
        }
    }

    isSelect(value): boolean {
        return this.emails.findIndex(e => e.email == value.email) >= 0
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const value = event.option.value;
        this.addEmail(value);
    }

    addEmail(value) {
        const isString = typeof value == 'string';
        const slug = isString ? value : value['email'];
        const ind = this.emails.findIndex(et => this.replaceStrangeCaracters(et.email.toLowerCase()) == this.replaceStrangeCaracters(slug.toLowerCase().trim()));
        if ((value || '')) {
            if (ind == -1) {
                if (isString) {
                    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if (re.test(String(value.trim()).toLowerCase())) {
                        this.emails.push({
                            displayName: value.trim(),
                            email: value.trim(),
                            isContact: false
                        });
                    }
                } else {
                    this.emails.push(value);
                }
            } else {
                this.swal.showAlert('El contacto se encuentra en la lista', 'info');
            }
        }
        this.emailForm.get('to').setValue(null);
    }

    send() {
        let message = this.emailForm.get('message').value;
        this.buttonSubmit = true;
        const destin = [...this.emails].map(el => {
            return {
                name: el.displayName.includes('@') ? '' : el.displayName,
                email: el.email
            }
        });
        switch (this.data.type) {
            case 'nota':
                this.sendNote(destin)
                break;
            case 'reporte':
                this.sendReport(destin)
                break;
            case 'impacto':
                this.sendImpact(destin)
                break;
        }
    }

    sendReport(destin) {
        const send = {
            ...this.data.report,
            asunto: this.emailForm.get('subject').value,
            destinatarios: destin,
            mensaje: this.emailForm.get('message').value
        }

        this.mediaplanService.sendEmailReport(send).subscribe({
            next: (response: any) => {
                this.swal.showToast('Correo enviado Satisfactoriamente!', 'success');
                this.buttonSubmit = false;
                this.dialogRef.close({action: true});
            },
            error: (error) => {
                this.swal.showToast(error.error.message, 'error');
                this.buttonSubmit = false;
            }
        }
            
            
        );

    }

    sendImpact(destin) {
        const send = {
            idCliente: this.data.customer.id,
            campaigns: this.data.report.campaigns,
            plataformas: this.data.report.plataformas,
            fechaInicio: this.data.report.fechaInicio,
            fechaFin: this.data.report.fechaFin,
            asunto: this.emailForm.get('subject').value,
            destinatarios: destin,
            mensaje: this.emailForm.get('message').value
        }

        this.mediaplanService.sendEmailImpact(send).subscribe(
            (response: any) => {
                this.swal.showToast(response.message, 'success');
                this.buttonSubmit = false;
                this.dialogRef.close({action: true});
            },
            (error) => {
                this.swal.showToast(error.error.message, 'error');
                this.buttonSubmit = false;
            }
        );

    }

    sendNote(destin) {
        const dataSave = {
            mensaje: this.emailForm.get('message').value,
            destinatorios: destin,
            asunto: this.emailForm.get('subject').value,
            archivos: this.selectedFile,
            idPlanMedio: this.data.idPlan
        }

        const formData = new FormData();
        formData.append('mensaje', dataSave.mensaje);
        formData.append('asunto', dataSave.asunto);
        formData.append('idPlanMedio', dataSave.idPlanMedio.toString());
        formData.append('destinatarios', JSON.stringify(dataSave.destinatorios));

        dataSave.archivos.forEach((fil, ind) => {
            if (fil) {
                formData.append('archivos[' + ind + ']', fil, fil.name);
            }
        })

        this.mediaplanService.sendEmail(formData).subscribe(
            (response: any) => {
                this.swal.showToast(response.message, 'success');
                this.buttonSubmit = false;
                this.dialogRef.close({action: true});
            },
            (error) => {
                this.swal.showToast(error.error.message, 'error');
                this.buttonSubmit = false;
            }
        );
    }

    replaceStrangeCaracters(strTHML) {
        return strTHML.replace(/á/gi, 'a')
            .replace(/é/gi, 'e')
            .replace(/í/gi, 'i')
            .replace(/ó/gi, 'o')
            .replace(/ú/gi, 'u')
            // .replace(/ñ/gi, "&Ntilde;")
            .replace(/°/gi, ' ')
            .replace(/\?/gi, '')
            .replace(/¿/gi, '')
            .replace(/\!/gi, '')
            .replace(/¡/gi, '')
            .replace(/¨/gi, '')
            .replace(/®/gi, '')
            .replace(/@/gi, '')
            .replace(/\$/gi, '')
            .replace(/%/gi, '')
            .replace(/Ü/gi, 'u');
    }

}
