import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProgramService } from 'src/app/core/services/api/program.service';
import { Category } from 'src/app/core/contracts/models/category';
import { CategoryService } from 'src/app/core/services/api/category.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlatformService } from '../../../core/services/api/platform.service';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import { Program } from 'src/app/core/contracts/models/programa.model';

@Component({
    selector: 'app-asignar-programas-form',
    templateUrl: './asignar-programas-form.component.html',
    styleUrls: ['./asignar-programas-form.component.scss']
})
export class AsignarProgramasFormComponent implements OnInit {
    public loading: boolean = false;

    myForm = this.formBuilder.group({
        name: new FormControl<string | null>(null, [Validators.required]),
        description: new FormControl<string | null>(null),
        categories: new FormControl<Array<any> | null>(null),
        periodicidad: new FormControl<Array<any> | null>(null),
        filterC: new FormControl<string | null>(null),
    });

    program: Program = {
        id: null,
        medio_id: null,
        nombre: null,
        periodicidad: null,
        descripcion: null,
        categorias: null
    }

    programs: Program[] = [];
    weekdays: any[] = [];

    title = 'Agregar programa';

    categories: Category[] = [];
    selectCategories$: Observable<Category[]>;

    platforms = [];

    constructor(
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AsignarProgramasFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { row: any, id: number },
        private programService: ProgramService,
        private categoryService: CategoryService,
        private platformService: PlatformService,
        private swal: Swal2
    ) {
        if (data.row) {
            this.title = 'Editar programa';
        }
    }

    ngOnInit(): void {
        this.categoryService.legacyGetAll().subscribe((response: any) => {
            const { categorias } = response;
            this.categories = categorias;
            if (this.data.row) {
                this.programService.getId(this.data.row.id).subscribe((response: Program) => {
                    this.myForm.controls.name.setValue(response.nombre);
                    this.myForm.controls.description.setValue(response.descripcion);
                    this.myForm.controls.categories.setValue(response.categorias);
                    this.weekdays = response.periodicidad ? response.periodicidad.split(',') : [];
                    this.myForm.controls.periodicidad.setValue(this.weekdays);
                    this.loadPlatforms();
                });
            } else {
                this.loadPlatforms();
            }
        });

        this.selectCategories$ = this.myForm.controls.categories.valueChanges.pipe(
            map(plats => {
                if (plats) {
                    return this.categories.filter(pl => plats.includes(pl.id));
                } else {
                    return [];
                }
            })
        )
    }

    loadPlatforms() {
        this.platformService.getListByMedio(this.data.id).subscribe((response: any) => {
            const { medioPlataformas } = response;
            if (this.data.row && this.data.row.programa_plataformas) {
                const assignedPlatforms = this.data.row.programa_plataformas.map(pp => ({
                    medio_plat: pp.medio_plat,
                    medio_plat_clas: pp.medio_plat_clas,
                    medio_plat_valor: pp.medio_plat_valor
                }));
                
                this.platforms = medioPlataformas.map(mp => {
                    const isAssigned = assignedPlatforms.some(ap => 
                        ap.medio_plat === mp.plataforma_clasificacion.plataforma.descripcion &&
                        ap.medio_plat_clas === mp.plataforma_clasificacion.descripcion &&
                        ap.medio_plat_valor === mp.valor
                    );
                    return {
                        ...mp,
                        check: isAssigned
                    };
                });
            } else {
                this.platforms = medioPlataformas.map(mp => ({
                    ...mp,
                    check: false
                }));
            }
        });
    }

    onSubmit() {
        this.program = {
            medio_id: this.data.id,
            nombre: this.myForm.controls.name.value,
            descripcion: this.myForm.controls.description.value,
            periodicidad: this.myForm.controls.periodicidad.value ? this.myForm.controls.periodicidad.value.join() : '',
            categorias: this.myForm.controls.categories.value,
        }
        let observable: Observable<any> | undefined = undefined;
        if (this.data.row) {
            this.program.id = this.data.row.id;
            this.program.mediosPlataformas = this.platforms.filter(pt => pt.check).map(pt => pt.id)
            observable = this.programService.update(this.program)
        } else {
            this.program.mediosPlataformas = this.platforms.filter(pt => pt.check).map(pt => pt.id)
            observable = this.programService.create(this.program)
        }
        this.loading = true;
        observable.subscribe((response: any) => {
            this.dialogRef.close(response);
            this.loading = false;
            this.swal.showToast(response.message, 'success');
        }, error => {
            if (error.status === 400) {
                this.error(error);
            }
            this.loading = false;
        });
    }

    remove(id): void {
        let preSelects = [...this.myForm.controls.categories.value];

        const index = preSelects.indexOf(id);
        if (index != -1) {
            const filteredOptions = [...preSelects].filter(op => op != id);
            this.myForm.controls.categories.patchValue(filteredOptions);
        }
    }

    error(error) {
        if (error.error.errors.nombre) {
            this.swal.showToast(error.error.errors.nombre[0], 'error');
        }
    }
}