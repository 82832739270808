import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export type imageTypes = 'avatar' | 'picture';

@Component({
    selector: 'app-image',
    templateUrl: './image.component.html',
})
export class ImageComponent { 
    
    public defaultImage: string = environment.images.DEFAULT_AVATAR_PATH;

    @Input() img: string|SafeUrl = '';
    @Input() alt: string;
    @Input() css: string = '';
    
    @Input() set type(type: string) {
        const defaultImages = {
            avatar : environment.images.DEFAULT_AVATAR_PATH,
            picture : environment.images.DEFAULT_NO_IMAGE
        };
        this.defaultImage = defaultImages[type];
    }

    @Output() loaded = new EventEmitter<boolean>()

    loading() {
        this.loaded.emit(true);
    }

    imgError() {
        console.warn('La imagen no pudo cargarse correctamente en app-image-component:', this.img)
        this.img = this.defaultImage;
        this.loaded.emit(false);
    }
}
