<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form [formGroup]="myForm">
        <div mat-card-content>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                <mat-form-field class="w-full">
                    <mat-label>Plataforma</mat-label>
                    <mat-select formControlName="platform" required>
                        <mat-option>Ninguno</mat-option>
                        <mat-option *ngFor="let p of platforms" [value]="p.id">{{p.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Completa este campo.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label>Clasificación</mat-label>
                    <mat-select formControlName="classification" required>
                        <mat-option>Ninguno</mat-option>
                        <mat-option *ngFor="let c of classifications" [value]="c.id">{{c.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error>
                        Completa este campo.
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label>Nº Señal / URL</mat-label>
                    <input matInput type="text" autocomplete="off" formControlName="url">
                </mat-form-field>
                <mat-form-field class="w-full">
                    <mat-label>Alcance</mat-label>
                    <input matInput type="number" autocomplete="off" formControlName="scope">
                </mat-form-field>
                <mat-form-field class="w-full md:col-span-2">
                    <mat-label>Valor (USD)</mat-label>
                    <input matInput type="number" autocomplete="off" formControlName="valor">
                </mat-form-field>
                <mat-form-field class="w-full md:col-span-2">
                    <textarea matInput placeholder="Observación" formControlName="observation"></textarea>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>