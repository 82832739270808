import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
    selector: 'adp-button-icon',
    templateUrl: './button-icon.component.html',
})
export class ButtonIconComponent {
    
    @Input() label: string = 'Guardar';
    @Input() color: ThemePalette = undefined;
    @Input() type: 'button'|'submit' = 'button'
    @Input() disabled: boolean = false;
    @Input() loading: boolean = false;
    @Input() icon: string = 'save';

    @Output() public clicked = new EventEmitter<unknown>();
    public onClicked() {
      this.clicked.emit(true)
    }
    
    get disableButton(): boolean {
        return this.loading || this.disabled
    }
  
    get iconButton() {
        return this.loading ? 'autorenew' : this.icon
    }
}
