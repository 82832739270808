import { NgModule } from '@angular/core';
import { AccordionAnchorDirective } from './accordionanchor.directive';
import { AccordionLinkDirective } from './accordionlink.directive';
import { AccordionDirective } from './accordion.directive';

@NgModule({
  declarations: [
    AccordionAnchorDirective, 
    AccordionLinkDirective, 
    AccordionDirective
  ],
  exports: [
    AccordionAnchorDirective, 
    AccordionLinkDirective, 
    AccordionDirective
  ]
})
export class AccordionModule { }
