import { Component, inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export interface NotificationSnackbarDto {
    icon: string;
    message: string;
    description: string;
};

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    standalone: true,
    imports: [MatIconModule]
})
export class NotificationComponent { 

    public snackBarRef: MatSnackBarRef<NotificationComponent> = inject(MatSnackBarRef);

    public data:NotificationSnackbarDto = inject(MAT_SNACK_BAR_DATA);
}


