import {Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Swal2 } from 'src/app/core/utils/swal2.util';
import {PeopleService} from 'src/app/core/services/api/people.service';

@Component({
    selector: 'app-email-edit',
    templateUrl: './email-edit.component.html',
    styleUrls: ['./email-edit.component.scss']
})
export class EmailEditComponent implements OnInit {
    rows: any[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    numberChange = 1;

    constructor(
        public dialogRef: MatDialogRef<EmailEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private peopleService: PeopleService,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
        this.getAll();
    }

    getAll() {
        this.peopleService.getEmailsByContact(this.data.contacto.id).subscribe(resp => {
            this.rows = resp['personaEmails'];
        })
    }

    close() {
        this.dialogRef.close({change: this.numberChange > 1});
    }

    deleteEmail(id) {
        this.swal.deleteItem().then(val => {
            if (val) {
                this.peopleService.deleteEmail(id).subscribe((response: any) => {
                    this.getAll();
                    this.swal.showTitleAlert('Eliminado', response.message, 'success');
                }, error => {
                    this.swal.showErrorAlert(error.error.message);
                });
            }
        });
    }
}
