<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form [formGroup]="myForm">
        <div class="grid grid-cols-1 gap-2">

            <mat-form-field>
                <mat-label>Cliente</mat-label>
                <mat-select disableOptionCentering formControlName="cliente">
                    <mat-option>
                        <ngx-mat-select-search formControlName="filter" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let c of clientes| search: myForm.get('filter').value:['nombreComercial']"
                        [value]="c.id">{{c.nombreComercial}}</mat-option>
                </mat-select>
                <button mat-button *ngIf="myForm.get('cliente').value" matSuffix aria-label="Clear"
                    (click)="clear('cliente')">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Campaña</mat-label>
                <mat-select disableOptionCentering formControlName="campania">
                    <mat-option>
                        <ngx-mat-select-search formControlName="filterC" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let c of campaigns| search: myForm.get('filterC').value:['titulo']"
                        [value]="c.id">{{c.titulo}}</mat-option>
                </mat-select>
                <button mat-button *ngIf="myForm.get('campania').value" matSuffix aria-label="Clear"
                    (click)="clear('campania')">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
            <mat-form-field >
                <mat-label>Plan de Medio</mat-label>
                <mat-select disableOptionCentering formControlName="planMedios">
                    <mat-option>
                        <ngx-mat-select-search formControlName="filterP" placeholderLabel="Buscar..."
                            noEntriesFoundLabel="'Sin Resultados'"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let p of planMedios| search: myForm.get('filterP').value:['nombre']"
                        [value]="p.id">{{p.nombre}}</mat-option>
                </mat-select>
                <button mat-button *ngIf="myForm.get('planMedios').value" matSuffix aria-label="Clear"
                    (click)="clear('planMedios')">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-error>
                    Completa este campo.
                </mat-error>

            </mat-form-field>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" mat-dialog-close type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="myForm.invalid" [loading]="loading"
        label="Guardar"></adp-button-icon>
</div>