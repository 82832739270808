import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

import { Swal2 } from 'src/app/core/utils/swal2.util';
import {TypesOfPhonesFormComponent} from '../form/types-of-phones-form.component';
import {MatDialog} from '@angular/material/dialog';
import { Phone } from 'src/app/core/contracts/models/phone';

@Component({
    selector: 'app-types-of-phones-list',
    templateUrl: './types-of-phones-list.component.html',
    styleUrls: ['./types-of-phones-list.component.scss']
})
export class TypesOfPhonesListComponent implements OnInit {

    @Output() onPhones = new EventEmitter<any>();
    @Input() data: Phone[] = [];
    messages = {emptyMessage: 'No hay información para mostrar.'};

    constructor(
        public dialog: MatDialog,
        private swal: Swal2
    ) {
    }

    ngOnInit(): void {
    }

    openDialog(row?: Phone) {
        const dialogRef = this.dialog.open(TypesOfPhonesFormComponent, {
            data: row,
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.addPhone(result);
            }
        });
    }

    addPhone(phone: Phone) {
        const rows = [...this.data];
        rows.push(phone);
        this.data = rows;
        this.onPhones.emit(rows);
    }

    editPhone(row: Phone, id: number) {
        const rows = [...this.data];
        rows.splice(id, 1);
        this.data = rows;
        this.openDialog(row);
        this.onPhones.emit(rows);
    }

    deletePhone(id) {
        this.swal.deleteItem().then(val => {
            if (val) {
                const rows = [...this.data];
                rows.splice(id, 1);
                this.data = rows;
                this.onPhones.emit(rows);
                this.swal.showTitleAlert('Eliminado', 'Registro eliminado con éxito', 'success');
            }
        });
    }
}
