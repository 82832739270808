import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { TimingService } from 'src/app/core/services/timing.service';

@Injectable()
export class TimingInterceptor implements HttpInterceptor {

    private apiSize: string = '2500';
    constructor( private timingService: TimingService ) {}

    intercept( request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        const startTime = Date.now();
        return next.handle( request ).pipe(
            tap(event => { 
                if( event instanceof HttpResponse ) { this.apiSize = event.headers.get('content-length'); }
            }),
            finalize( () => {
                const endTime = Date.now();
                const elapsedTime = endTime - startTime;
                this.timingService.addResponseRate( parseInt(this.apiSize), elapsedTime );
            })
        );
    }
}
