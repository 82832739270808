<div style="margin: 16px 0;">
    <div class="px-4 flex flex-row flex-wrap justify-between items-center content-center">
        <h3 style="font-size: 16px;">Correo</h3>
        <button type="button" mat-raised-button mat-button-sm color="primary" class="me-4 mb-4" (click)="openDialog()">
            Agregar correo
        </button>
    </div>

    <ngx-datatable class="material" [messages]="messages" [rows]="data" [columnMode]="'force'" [headerHeight]="45"
        rowHeight="auto">

        <ngx-datatable-column name="#" [width]="10" [canAutoResize]="false" [sortable]="false" [draggable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}
            </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Tipo" prop="tipo_email.name"></ngx-datatable-column>
        <ngx-datatable-column name="Correo" prop="email"></ngx-datatable-column>
        <ngx-datatable-column name="Acciones" [width]="200" [canAutoResize]="false" [sortable]="false">
            <ng-template let-value="value" ngx-datatable-cell-template let-row="row" let-rowIndex="rowIndex">
                <button mat-stroked-button mat-button-sm color="primary" type="button" class="me-2"
                    (click)="editEmail(row, rowIndex)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button mat-button-sm  type="button" (click)="deleteEmail(rowIndex)">
                    <mat-icon>delete</mat-icon>
                </button>
            </ng-template>
        </ngx-datatable-column>

    </ngx-datatable>
</div>