import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Api } from '../../contracts/classes/api.class';
import { IResponse } from '../../contracts/models/response.model';
import { CreateTierDTO, Tier, UpdateTierDTO } from '../../contracts/models/tier.model';

@Injectable({
  providedIn: 'root'
})
export class TiersService extends Api {

  tiersBs: BehaviorSubject<Tier[]> = new BehaviorSubject<Tier[]>([]);

  getAll(): Observable<IResponse<Tier[]>> {
    return this.http.get<IResponse<Tier[]>>(`${this.url}/tiersMantenimiento`);
  }

  create(createTierDTO: CreateTierDTO): Observable<IResponse<Tier>> {
    return this.http.post<IResponse<Tier>>(`${this.url}/tiersMantenimiento`, createTierDTO);
  }

  update(updateTierDTO: UpdateTierDTO): Observable<IResponse<Tier>> {
    return this.http.put<IResponse<Tier>>(`${this.url}/tiersMantenimiento/${updateTierDTO.id}`, updateTierDTO);
  }

  delete(id: number): Observable<IResponse<Tier>> {
    return this.http.delete<IResponse<Tier>>(`${this.url}/tiersMantenimiento/${id}`);
  }

  getTiers(): Tier[] {
    return this.tiersBs.getValue();
  }

  updateTiers(tiers: Tier[]) {
    this.tiersBs.next(tiers);
  }

}