<div class="wrapper">

    <mat-icon>error_outline</mat-icon>
    
    <header>
        <h1>{{ title }}</h1>
    </header>

    <section class="content">
        <p class="text">{{ text }}</p>
        <p class="text">{{ textAlert }}</p>
    </section>
    
    <div class="buttons">
        <button
            class="btn-aceptar"
            mat-raised-button
            color="primary"
            (click)="onNoClick('true')">
            Aceptar
        </button>
        <button
            *ngIf="textAlert"
            class="btn-ver-campanias"
            mat-raised-button
            color="accent"
            (click)="onNoClick('campaign')">
            Ver campañas
        </button>
        <button
            class="btn-cancelar"
            mat-raised-button
            color="warn"
            (click)="onNoClick('false')">
            Cancelar
        </button>
    </div>
    
</div>