import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Select } from 'src/app/core/contracts/models/form-input.model';
import { Api } from '../../contracts/classes/api.class';
import { CreatePlatformDTO, Platform, UpdatePlatformDTO } from '../../contracts/models/platform';
import { PlatformMedia } from '../../contracts/models/medio-plataformas';

@Injectable({ providedIn: 'root' })
export class PlatformService extends Api {

  platformsBs: BehaviorSubject<Platform[]> = new BehaviorSubject<Platform[]>([]);

  public getAll() {
    return this.http.get(`${this.url}/plataformas`);
  }

  create(data: CreatePlatformDTO) {
    return this.http.post(`${this.url}/plataformas`, data);
  }

  update(data: UpdatePlatformDTO) {
    return this.http.put(`${this.url}/plataformas/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/plataformas/${id}`);
  }

  getAllMedioPlataformas() {
    return this.http.get(`${this.url}/medioPlataformas`);
  }

  getAllMedioPlataformasId(id) {
    return this.http.get(`${this.url}/medioPlataformas/${id}`);
  }

  createMedioPlataformas(data: PlatformMedia) {
    return this.http.post(`${this.url}/medioPlataformas`, data);
  }

  updateMedioPlataformas(data: PlatformMedia) {
    return this.http.put(`${this.url}/medioPlataformas/${data.id}`, data);
  }

  deleteMedioPlataformas(id: number) {
    return this.http.delete(`${this.url}/medioPlataformas/${id}`);
  }

  getListByMedio(id: number) {
    return this.http.get(`${this.url}/medioPlataformas/data/getListByMedio/${id}`);
  }

  getPlatforms() {
    return this.platformsBs.getValue();
  }

  updatePlatforms(platforms: Platform[]) {
    this.platformsBs.next(platforms);
  }

  /**
   * @description Observable con la lista de plataformas
   * @method GET
   * @version APIv2.0
   * @returns 
   */
  public all(): Observable<Platform[]> {
    return this.http.get<Platform[]>(`${this.urlv2}/plataformas`);
  }

  /** @version APIv2.0 */
  public allToSelect(): Observable<Select[]> {
    return this.all().pipe(
      map( platforms => platforms.map( platform => ({
        id:    platform.id,
        label: platform.descripcion
      })))
    )
  }
}
