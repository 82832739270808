import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";


@Component({
    selector: 'app-user-confirm-dialog',
    templateUrl: './user-confirm-dialog.component.html',
    styleUrls: ['./user-confirm-dialog.component.scss']
})
export class UserConfirmDialogComponent {

    title: string;
    text: string;
    textAlert: string;

    constructor(
        public dialogRef: MatDialogRef<UserConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.title = data.title;
        this.text = data.text;
        this.textAlert = data.textAlert;
    }

    onNoClick(result: string): void {
        this.dialogRef.close(result);
    }

}