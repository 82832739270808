<div mat-dialog-title class="!text-base">{{title}}</div>
<div mat-dialog-content class="py-0">
    <form #emailForm="ngForm" class="grid gap-y-2">
        <div class="grid sm:grid-cols-2 gap-x-4 gap-y-2">
            <mat-form-field class="w-full">
                <mat-label>Tipo</mat-label>
                <mat-select [(ngModel)]="horario.idTipoHorario" name="idTipoHorario" required>
                    <mat-option>Ninguno</mat-option>
                    <mat-option *ngFor="let h of horarios" [value]="h.id">{{h.name}}</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Días</mat-label>
                <mat-select [(ngModel)]="horario.periodicidad" name="periodicidad" required multiple>
                    <mat-option value="Lu">Lunes</mat-option>
                    <mat-option value="Ma">Martes</mat-option>
                    <mat-option value="Mi">Miércoles</mat-option>
                    <mat-option value="Ju">Jueves</mat-option>
                    <mat-option value="Vi">Viernes</mat-option>
                    <mat-option value="Sa">Sábado</mat-option>
                    <mat-option value="Do">Domingo</mat-option>
                </mat-select>
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>

            <mat-form-field class=" w-full">
                <mat-label>Inicio</mat-label>
                <input matInput required type="time" [(ngModel)]="horario.horaInicio" name="horaInicio">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Fin</mat-label>
                <input matInput required type="time" [(ngModel)]="horario.horaFin" name="horaFin">
                <mat-error>
                    Completa este campo.
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="w-full">
            <textarea matInput placeholder="Descripción" [(ngModel)]="horario.descripcion" name="descripcion"
                required></textarea>
            <mat-error>
                Completa este campo.
            </mat-error>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions align="end" class="!px-6">
    <button mat-stroked-button color="primary" class="me-4" (click)="cancel()" type="button">Cancelar</button>
    <adp-button-icon (clicked)="onSubmit()" color="primary" [disabled]="emailForm.invalid"
        label="Agregar"></adp-button-icon>
</div>