import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InternetConnecionDto, InternetConnectionComponent } from '../../shared/snackbars/internet-connection/internet-connection.component';
import { ConnectionStatus } from 'src/app/core/services/PWA/connection.service';
import { NotificationComponent, NotificationSnackbarDto } from 'src/app/shared/snackbars/notification/notification.component';
import { NotificationWithButtonsComponent, NotificationWithButtonsSnackbarDto } from 'src/app/shared/snackbars/notification-with-buttons/notification-with-buttons.component';

/** 
 * @description Las llaves deben ser del tipo ConnectionStatus
 * @type ConnectionStatus 
 * */
export interface SnackBarMessage {[key:string]: InternetConnecionDto};

export const SNACKBAR_MESSAGE:SnackBarMessage = {
  noInternet: {
    message: 'No tiene conexión a Internet',
    description: 'Asegúrese de estar conectado a Internet para continuar.',
    icon: 'error'
  },
  disconnected: {
    message: 'Ha perdido la conexión a Internet',
    description: 'Por favor, revise su conexión a Internet e inténtelo de nuevo',
    icon: 'warning'
  },
  connected: {
    message: 'Conexión a Internet restablecida',
    description: 'Porfavor, actualice la página',
    icon: 'router'
  }
}

export type SNACKBAR_ICON = 'warning' | 'error' | 'router';

@Injectable({providedIn: 'root'})
export class SnackBarService {

  private readonly duration_ms: number = 8000;

  private _snackBar = inject(MatSnackBar);

  public renderConnection(notification: ConnectionStatus, css: string) {
    const data = SNACKBAR_MESSAGE[notification];
    return this._snackBar.openFromComponent(InternetConnectionComponent, {
      data,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      duration: this.duration_ms,
      panelClass: ['adp-snackbar', css]
    });
  }

  /** NOTIFICACIONES NORMALES */
  public renderNotification(data: NotificationSnackbarDto, css: string) {
    return this._snackBar.openFromComponent(NotificationComponent, {
      data,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      duration: 3000,
      panelClass: ['adp-snackbar', css]
    });
  }

  public renderInfoNotification(message: string, description: string) {
    return this.renderNotification({ icon: 'info', message, description }, 'adp-info-snackbar');
  }

  public renderSuccessNotification(message: string, description: string) {
    return this.renderNotification({ icon: 'check_circle', message, description }, 'adp-success-snackbar');
  }

  public renderDangerNotification(message: string, description: string) {
    return this.renderNotification({ icon: 'check_circle', message, description }, 'adp-danger-snackbar');
  }


  /** NOTIFICACIONES CON BOTONES */
  public renderNotificationWithButtons(data: NotificationWithButtonsSnackbarDto, css: string) {
    return this._snackBar.openFromComponent(NotificationWithButtonsComponent, {
      data,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: ['adp-snackbar', css]
    });
  }

  public renderDangerNotificationWithButtons(message: string, description: string, withActionButton: boolean = true) {
    return this.renderNotificationWithButtons({ icon: 'error', message, description, withActionButton }, 'adp-danger-snackbar');
  }
  
}