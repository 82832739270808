import { Injectable } from '@angular/core';
import { Api } from '../../contracts/classes/api.class';

@Injectable({providedIn: 'root'})
export class CargosService extends Api {

  getAll() {
    return this.http.get(`${this.url}/cargos`);
  }

  create(data) {
    return this.http.post(`${this.url}/cargos`, data);
  }

  update(data) {
    return this.http.put(`${this.url}/cargos/${data.id}`, data);
  }

  delete(id: number) {
    return this.http.delete(`${this.url}/cargos/${id}`);
  }
}
