<div style="display: flex; flex-direction: row;">
    <div class="snackbar-icon">
        <mat-icon>{{data.icon}}</mat-icon>
    </div>
    <div style="margin: 0;">
        <p class="snackbar-title">{{data.message}}</p>
        <p class="snackbar-label">{{data.description}}</p>
    </div>
</div>
<div style="display: flex; flex-direction: row; justify-content:space-between; margin-top: 8px;">
    <button *ngIf="data.withActionButton" type="button" class="snackbar-button snackbar-button-action" (click)="showDetails()" style="margin: 0 8px;">Ver detalle</button>
    <button type="button" class="snackbar-button snackbar-button-close" (click)="close()" style="margin: 0 8px;">Cerrar</button>
</div>
