<div mat-dialog-title>
    Detalle del Impacto
</div>

<hr>

<mat-dialog-content>
    <table >
        <tr>
            <td><b>Periodista</b></td>
            <td><b>:</b></td>
            <td>{{ nombre }}</td>
        </tr>
        <tr>
            <td><b>Medio: Programa</b></td>
            <td><b>:</b></td>
            <td><b>{{ medio }}: </b>{{ programa }}</td>
        </tr>
    </table>
    
    <hr>

    <div class="platform-section">
        <p><b>Plataformas Publicadas:</b></p>
        <a *ngFor="let plataforma of plataformas" target="_blank" href="{{ plataforma.url }}">
            <div class="platform-card">
                <span> {{ plataforma.plataforma }} <b> >> </b>{{ plataforma.clasificacion }}<b> >> </b>{{ plataforma.label }} </span>
                <br>
                <span> Publicado en: {{ plataforma.fechaPublicada }}</span>
            </div>
        </a>
    </div>

    <hr>

    <table>
        <tr>
            <td style="vertical-align: baseline;"><b>Voceros</b></td>
            <td style="vertical-align: baseline;"><b>:</b></td>
            <td><tr *ngFor="let vocero of voceros">{{ vocero }}</tr></td>
        </tr>
    </table>

    <hr>

    <table style="width: 100%; text-align: center;">
        <tr style="width: 100%;">
            <td style="width: 50%;">
                <b>Resumen: </b>{{ observacion }}
            </td>
            <td style="width: 50%;">
                <b>Tipo de Comunicación: </b>{{ tipo_comunicacion }}
            </td>
        </tr>
    </table>

    <table style="width: 100%;">
        <tr style="width: 100%;">
            <td style="width: 33%;"><b> Tipo de Nota: </b>{{ tipoNota }} </td>
            <td style="width: 33%;"><b> Etapa: </b>{{ etapa }} </td>
            <td style="width: 33%;"><b> Tier: </b>{{ tier }} </td>
        </tr>

        <tr>
            <td style="width: 33%;"><b>M. Registradas: </b>{{ muestras_registradas }} </td>
            <td style="width: 33%;"><b>M. Enviadas: </b>{{ muestras_enviadas }} </td>
            <td style="width: 33%;"><b>M. Verificadas: </b>{{ muestras_verificadas }} </td>
        </tr>
    </table>
  
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" type="button" mat-dialog-close style="margin-right: 5px;">
        Cerrar
    </button>
</mat-dialog-actions>