<mat-tab-group mat-stretch-tabs>
  <mat-tab>
    <ng-template mat-tab-label>Notificaciones</ng-template>
    <div class="scroll">
     <mat-nav-list class="navigation relative">
        <div class="p-4 -mt-2 mat-text-muted" > Campañas por finalizar</div>
        <ng-container *ngFor="let cm of campaignList">
          <mat-list-item (click)="openNavSubMenu(cm)" class="!py-4" style="height: 100%;">
            <div  class="text-[15px] whitespace-normal">{{cm.titulo}}</div>
            <div  class="flex flex-row flex-wrap justify-between items-end mat-text-muted ">
              <span class="text-sm whitespace-normal">{{cm.cliente.nombreComercial}}</span>
              <div  class="text-sm">
                <span *ngIf="cm.isToday === 0">Hoy</span>
                <span *ngIf="cm.isToday === 1">Mañana</span>
                <span *ngIf="cm.isToday>1">Dentro de {{cm.isToday}} días</span>
              </div>
            </div>
            <mat-nav-list *ngIf="cm.open">
             <mat-list-item *ngFor="let plan of cm.plan_medios"
             (click)="gotToPlan(plan.id)"
               >
                <div class="text-sm whitespace-normal">{{plan.nombre }}</div>
              </mat-list-item>
            </mat-nav-list>

          </mat-list-item>
          <mat-divider></mat-divider>
        </ng-container>
      </mat-nav-list> 
      
    </div>
  </mat-tab>