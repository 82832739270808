import { Injectable } from '@angular/core';
import { Api } from '../contracts/classes/api.class';

@Injectable({
  providedIn: 'root'
})
export class ImageService extends Api {

  /**
   * @deprecated
   * @description Usado en Pipe
   * todo: reemplazar implementación por rutas directas a las imgs
   * @param url 
   * @returns 
   */
  get(url: string) {
    return this.http.get(url, { responseType: 'blob' }).toPromise();
  }

  avatar(path: string): string {
    return `${this.source}/picture/users/${path}`;
  }
}
