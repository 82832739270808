import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { resultadoResponseAPI } from 'src/app/core/contracts/models/impactos';
import { Api } from '../../contracts/classes/api.class';

@Injectable({ providedIn: 'root' })

export class ResultadosPlataformasService extends Api{

    getByDPM(id:number) {
        return this.http.get<resultadoResponseAPI[]>(`${this.url}/resultadoPlataformas/data/getListByDPM/${id}`)
        .pipe(map( response => response['resultados'] ))
    }

    byCampaigns(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorCampanasAndPlataformas`, data);
    }

    bySpokesmen(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorVocerosAndPlataformas`, data);
    }

    byCampaignsLogged(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorCampanasAndPlataformasByLogged`, data);
    }

    bySpokesmenLogged(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorVocerosAndPlataformasByLogged`, data);
    }

    ByRegion(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorTipoRegion`, data);
    }

    ByTier(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorTipoTier`, data);
    }

    ByRegionLogged(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorTipoRegionByLogged`, data);
    }

    ByTierLogged(data) {
        return this.http.post(`${this.url}/detallePlanResultadoPlataformas/data/impactosPorTipoTierByLogged`, data);
    }

}
